import Button from 'components/UI/Button'
import ConfigService, { AssetNames } from 'config'
import { scrollToElement } from 'containers/PhotographersLanding/utils/utils'
import { useKeenSlider } from 'keen-slider/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Tracker from 'shared/tracking'

const PhotographerReviews = () => {
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = React.useState<number>(0)
  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    detailsChanged(s) {
      setActiveIndex(s.track.details.rel)
    },
    initial: 0,
  })

  const breadcrumbs = (totalSlides: number, activeIndex: number) => (
    <div className="flex gap-1 items-center">
      {[...Array(totalSlides)].map((_, index) => (
        <div
          key={index}
          className={`${
            index === activeIndex
              ? 'bg-primary w-2.5 h-2.5'
              : 'bg-lumepic-dark_grey opacity-80 w-2 h-2'
          }`}
          style={{ borderRadius: '50%' }}
        />
      ))}
    </div>
  )

  const data = [
    {
      src: ConfigService.getAsset(
        AssetNames.PHOTOGRAPHERS_LANDING_REVIEWS_1,
        'photographers-landing'
      ),
    },
    {
      src: ConfigService.getAsset(
        AssetNames.PHOTOGRAPHERS_LANDING_REVIEWS_2,
        'photographers-landing'
      ),
    },
    {
      src: ConfigService.getAsset(
        AssetNames.PHOTOGRAPHERS_LANDING_REVIEWS_3,
        'photographers-landing'
      ),
    },
    {
      src: ConfigService.getAsset(
        AssetNames.PHOTOGRAPHERS_LANDING_REVIEWS_4,
        'photographers-landing'
      ),
    },
  ]

  return (
    <div className="bg-gradient-to-b from-lumepic-light_black to-lumepic-black w-full py-28 sm:py-44">
      <div className="container mx-auto">
        <div className="w-full flex justify-center sm:justify-between items-center mb-8 lg:mb-10">
          <h3 className="text-center text-2xl md:text-3xl lg:text-4xl text-white font-bold">
            {t('Reviews')}
          </h3>
          <div className="hidden sm:block">
            <Button
              btnType="SecondaryAction"
              onClick={() => {
                Tracker.goToPhotographersRegister()
                scrollToElement('photographer-register')
              }}
            >
              {t('Create an account')}
            </Button>
          </div>
        </div>
        <div className="sm:hidden">
          <div ref={sliderRef} className="keen-slider">
            {data.map((item, idx) => (
              <div key={idx} className="keen-slider__slide px-2">
                <img
                  src={item.src}
                  alt="Photographer reviews"
                  className="w-full object-cover object-center"
                />
              </div>
            ))}
          </div>
          <div className="sm:hidden w-full flex justify-center mt-2">
            {breadcrumbs(data.length, activeIndex)}
          </div>
          <div className="mt-10 w-full flex justify-center">
            <Button
              btnType="SecondaryAction"
              onClick={() => {
                Tracker.goToPhotographersRegister()
                scrollToElement('photographer-register')
              }}
            >
              {t('Create an account')}
            </Button>
          </div>
        </div>
        <div className="hidden sm:block">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-2">
            {data.map((item, idx) => (
              <img
                key={idx}
                src={item.src}
                alt="Photographer review"
                className="w-full object-cover object-center"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhotographerReviews
