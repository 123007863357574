import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/UI/Button'

type RequestEventMessageProps = {
  onRequestEvent: () => void
}

const RequestEventMessage = ({ onRequestEvent }: RequestEventMessageProps) => {
  const { t } = useTranslation()

  return (
    <div className="p-2 rounded-md bg-bg_details mt-5 flex items-start gap-3 justify-between">
      <div className="flex flex-col">
        <span className="text-lumepic-light_black font-medium text-sm">
          {t('Haven’t you found the event?')}
        </span>
        <span className="text-xs mt-1.5 font-light">
          {t('If you cannot find the event, you can request it by clicking on the button below. ')}
          <strong>
            {t('IN THE MEANTIME, UPLOAD YOUR ALBUM WITHOUT ASSOCIATING IT WITH AN EVENT ')}
          </strong>
          {t(
            'and let us know through our communication channels so that we can link your album to the event as soon as possible.'
          )}
        </span>
        <div>
          <Button btnType="PrimaryAction" onClick={onRequestEvent} extraStyle="text-xs mt-4">
            {t('Request an event')}
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              className="text-lumepic-black ml-1.5"
            />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default RequestEventMessage
