import { useContext, useEffect } from 'react'
import storesContext from 'providers/storesContext'
import InfiniteScroll from 'react-infinite-scroll-component'
import Spinner from 'components/UI/Spinner'
import { faFolder, faImages, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LinearProgress } from '@mui/material'
import Button from 'components/UI/Button'
import Modal from 'components/UI/Modal'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import PackageLabel from 'components/UI/PackageLabel/PackageLabel'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { PackageCartLine } from '../../../../shared/models/Cart/CartLine/PackageCartLine'
import { isNil } from 'lodash'
import { BurstCartLine } from 'shared/models/Cart/CartLine/BurstCartLine'
import { CartLineType } from 'shared/models/Cart/CartLine/CartLine'

type PackageLineProps = {
  line: PackageCartLine | BurstCartLine
}

const PackageLine = ({ line }: PackageLineProps) => {
  const history = useHistory()
  const { cartStore } = useContext(storesContext)!
  const { t } = useTranslation()

  const [progress, setProgress] = useState(0)
  const [clearTimeoutTool, setClearTimeoutTool] = useState<NodeJS.Timeout | null>(null) // Change type to NodeJS.Timeout
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [showPackageImagesModal, setShowPackageImagesModal] = useState(false)

  const isEventPackage = !isNil(line.event)
  const currency = isEventPackage
    ? line.event!.currency.toUpperCase()
    : line.albums[0].currency.toUpperCase()

  useEffect(() => {
    const interval = 20
    const steps = 115 / (4000 / interval)
    const timer = setInterval(() => {
      setProgress((prevProgress) => Math.max(0, prevProgress - steps))
    }, interval)
    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    if (showPackageImagesModal) {
      cartStore.fetchPackagePhotographs(
        line.type === CartLineType.Package
          ? (line as PackageCartLine).tagId
          : (line as BurstCartLine).burstId,
        isEventPackage ? undefined : line.albums[0]?.id,
        isEventPackage ? line.event?.id : undefined,
        isEventPackage ? line.event?.password : line.albums[0]?.password
      )
    }
  }, [showPackageImagesModal])

  const goToEventOrAlbum = () => {
    const location = isEventPackage
      ? `/events/${line.event!.landingPath}`
      : `/feed/albums/${line.albums[0]?.id}`
    history.push(location)
  }

  const viewPackagePhotos = () => {
    setShowPackageImagesModal(true)
  }

  const removePackageFromCart = () => {
    cartStore.removeLine(line)
  }

  const deleteItem = () => {
    setProgress(100)
    setDeleteConfirmation(true)
    const deletePictureTimeout = setTimeout(removePackageFromCart, 4000)
    setClearTimeoutTool(deletePictureTimeout)
  }

  const undoDelete = () => {
    if (clearTimeoutTool !== null) {
      clearTimeout(clearTimeoutTool)
      setClearTimeoutTool(null)
    }
    setDeleteConfirmation(false)
  }

  const loadMorePhotographs = () => {
    if (!cartStore.isLoadingPackagePhotographs) {
      cartStore.fetchNextPage()
    }
  }

  return (
    <>
      {!deleteConfirmation && (
        <div className="w-full flex py-2.5 border-b relative bg-white border-b-lg md:rounded-b-lg">
          <div className="flex items-center w-full md:w-1/2 cursor-zoom-in">
            <FontAwesomeIcon icon={faFolder} className="hidden md:text-2xl md:mr-2" />
            <div className="h-full w-5/6 relative pl-2 md:px-0 md:pl-1">
              {line.previewPhotographs.map((photograph, index) => {
                // Only show first 3 photos to create package effect
                if (index >= 3) return null

                return (
                  <div
                    key={photograph.id}
                    className={`absolute w-full h-full transition-transform duration-300 hover:-translate-y-1 ${
                      index === 0 ? 'z-30' : index === 1 ? 'z-20 rotate-3' : 'z-10 rotate-6'
                    }`}
                    style={{
                      transform: `translateY(${index * 4}px)`,
                    }}
                  >
                    <img
                      src={photograph.url}
                      alt={photograph.id}
                      onClick={viewPackagePhotos}
                      className="object-cover object-center w-full h-full cursor-zoom-in md:rounded-md shadow-lg"
                    />

                    {index === 0 && (
                      <div className="absolute bottom-2 right-2 bg-white/90 px-2 py-1 rounded-md text-sm font-medium shadow-md">
                        {line.quantityOfPhotographs} {t('photos')}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>

          <div className="w-full flex flex-col justify-between ml-2 md:ml-6 md:mb-3">
            <div className="flex flex-col items-end font-semibold pt-2 pr-2 md:pl-2">
              <div className="w-full flex-col md:flex md:items-end">
                <div className="flex flex-col items-end">
                  <span className="font-light text-sm justify-end text-lumepic-grey line-through">
                    {currency} {line.previewPhotographs[0].price * line.quantityOfPhotographs}
                  </span>
                  {currency} {line.totalPrice}
                </div>
                <div className="md:w-1/2 ">
                  <PackageLabel />
                </div>
              </div>
            </div>

            <div className="flex flex-col items-start justify-end gap-2 mt-4">
              <div onClick={goToEventOrAlbum} className="flex items-center gap-2 cursor-pointer">
                <i className="text-lumepic-grey">
                  <FontAwesomeIcon icon={faImages} />
                </i>
                <span className="text-sm text-lumepic-grey lg:text-base">
                  {isEventPackage ? t('Go to event') : t('Go to album')}
                </span>
              </div>
              <div
                onClick={() => deleteItem()}
                className="ml-0.5 flex gap-2 items-center cursor-pointer"
              >
                <i className="text-lumepic-grey">
                  <FontAwesomeIcon icon={faTrashAlt} />
                </i>
                <span className="text-sm text-lumepic-grey lg:text-base">{t('Delete')}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteConfirmation && (
        <div className="w-full my-3 bg-bg_details">
          <LinearProgress variant="determinate" color="primary" value={progress} />
          <div className="flex flex-col items-center justify-center gap-2 p-5 min-h-20 max-h-48">
            <p className="mb-0 font-medium">{t('Picture deleted')}</p>
            <Button btnType="TertiaryAction" onClick={() => undoDelete()}>
              {t('Undo action')}
            </Button>
          </div>
        </div>
      )}
      <Modal
        onCancel={() => setShowPackageImagesModal(false)}
        opened={showPackageImagesModal}
        modalSize={ModalSize.MEDIUM_SMALL}
        buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
        displayCloseIcon={true}
      >
        <div className="flex flex-col h-[calc(100vh-100px)] md:h-[calc(100vh-120px)]">
          <h2 className="text-center text-lg font-semibold mb-4">
            {line.type === CartLineType.Burst ? t('Burst') : t('Package')} {t('of')}{' '}
            {cartStore.packagePhotographCount} {t('photos')}
          </h2>
          <InfiniteScroll
            dataLength={cartStore.packagePhotographs.length}
            next={loadMorePhotographs}
            hasMore={cartStore.hasMorePages()}
            loader={
              <div className="flex justify-center my-4">
                <Spinner />
              </div>
            }
            scrollThreshold={0.9}
            height="calc(100vh - 120px)"
            className="p-4"
          >
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-3 max-w-3xl mx-auto">
              {cartStore.packagePhotographs.map((photo) => (
                <div
                  key={photo.id}
                  className="aspect-square relative group w-full max-w-[280px] md:max-w-[320px] mx-auto"
                >
                  <img
                    src={photo.url}
                    alt={photo.id}
                    className="w-full h-full object-cover rounded-lg transition-transform duration-300 group-hover:scale-[1.02]"
                  />
                  <div className="absolute inset-0 bg-black/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg" />
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </Modal>
    </>
  )
}

export default observer(PackageLine)
