import React from 'react'
import Album from 'shared/models/Album'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import magnifyingGlass from '../../../../assets/icons/v2/magnifyingGlass.svg'
import { feedEventDetailsByLandingPath } from 'routing/Paths/feed'
import { isNil } from 'lodash'
import Button from 'components/UI/Button'

type AlbumDetailsNoResultProps = {
  album: Album
}

const AlbumDetailsNoResult = ({ album }: AlbumDetailsNoResultProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleEventRedirect = () => {
    if (!isNil(album?.event.landingPath)) {
      history.push(feedEventDetailsByLandingPath(album.event.landingPath))
    }
  }

  return (
    <div className="bg-white w-full mt-6 rounded-b text-lumepic-light_black text-base sm:text-xl py-4 shadow-md">
      <div className="flex items-center gap-2 justify-center">
        <img src={magnifyingGlass} alt="No Results" className="w-10 h-10 sm:w-16 sm:h-16" />
        <span className="font-medium">{t('No photographs were found')}</span>
      </div>

      {!isNil(album?.event.id) && (
        <div className="flex items-center flex-col px-4 mt-4 text-center">
          <p className="text-sm text-lumepic-grey mb-2">
            {t("This album is part of an event. You may appear in the event's photographs")}.
          </p>
          <Button btnType="PrimaryAction" onClick={handleEventRedirect} extraStyle="text-base">
            {t('View event photographs')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default AlbumDetailsNoResult
