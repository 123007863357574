import React, { useContext, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import EventDetailsStore from '../EventDetailsStore'
import { PhotographUtils } from 'shared/util/photographs.utils'
import { feedAlbumPhotographDetailById } from 'routing/Paths'
import Album from 'shared/models/Album'
import Tracker from 'shared/tracking'
import NoResultsFound from 'components/UI/NoResultsFound/NoResultsFound'
import { isNil } from 'lodash'
import PhotographModal from 'components/PhotographDetailsModal'
import { observer } from 'mobx-react'
import AlbumCards from 'components/Album/AlbumCards/AlbumCards'
import DetailsPhotographCard from 'components/AlbumDetails/components/DetailsPhotographCard'
import useHideChatBot from 'hooks/useHideChatBot'
import Checkbox from 'components/UI/Checkbox'
import { useTranslation } from 'react-i18next'
import { Photograph } from 'shared/models/Photograph'
import storesContext from 'providers/storesContext'
import { getFeatureFlagValue } from 'shared/utility'
import { FeatureFlags } from 'config/constants/featureFlags'
import BurstButton from 'components/UI/Button/BurstButton'
import { calculateTotalPhotographsPrice, getQtyDiscountPercentageEarned } from 'shared/utility'
import BatchedMasonryGallery from 'components/UI/DetailsPhotoGallery/BatchedMasonryGallery'

type EventDetailsContentProps = {
  photographToDisplayId: string | null
  setPhotographToDisplayId: React.Dispatch<React.SetStateAction<string | null>>
  store: EventDetailsStore
  findAlbumForPhotograph: (photographId: string) => Album | undefined
  searchInProgress: boolean
}

const EventDetailsContent = ({
  setPhotographToDisplayId,
  photographToDisplayId,
  store,
  findAlbumForPhotograph,
  searchInProgress,
}: EventDetailsContentProps) => {
  useHideChatBot()
  const { t } = useTranslation()
  const { cartStore, featureFlagsStore } = useContext(storesContext)!
  const {
    albums,
    photographs,
    event,
    tagIds,
    isLoadingEventDetails,
    isLoadingEventPhotographs,
    isLoadingEventAlbums,
  } = store

  const burstPurchaseFeatureFlag = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.BUY_BURSTS_PACKAGE,
    false
  )

  const [isBurstMode, setIsBurstMode] = useState(false)
  const photographBlocksByBurst = isBurstMode
    ? PhotographUtils.groupByBursts(photographs, 3000)
    : new Map([['All', photographs]])
  const handleBurstModeChange = (isChecked: boolean) => {
    setIsBurstMode(isChecked)
  }

  const handleDownloadFreePhotograph = (photographId: string) => {
    if (!event.isFree) {
      return
    }
    Tracker.downloadFreePhotograph(
      photographId,
      findAlbumForPhotograph(photographId)!.id,
      event.id,
      event.landingPath
    )
    store.downloadFreePhotograph(photographId).then((response) => {
      if (response) {
        const link = document.createElement('a')
        link.href = response.url
        link.click()
      }
    })
  }

  const setIntervalAccordionPhotographModalPhotograph = (photographId: string) => {
    const url = feedAlbumPhotographDetailById(
      findAlbumForPhotograph(photographId)!.id,
      photographId
    )
    window.history.replaceState(null, '', url)
    setPhotographToDisplayId(photographId)
  }

  const setPhotograph = (albumId: string, photographId: string) => {
    const url = feedAlbumPhotographDetailById(albumId, photographId)
    window.history.replaceState(null, '', url)
    setPhotographToDisplayId(photographId)
  }

  const closeImageModal = () => {
    setPhotographToDisplayId(null)
    const initialUrl = `/events/${event.landingPath}`

    const params = new URLSearchParams()
    if (tagIds.length > 0) {
      params.set('tagValue', tagIds.toString())
    }
    if (store.getOwnerId()) {
      params.set('ownerId', store.getOwnerId()!)
    }

    const queryString = params.toString()
    const finalUrl = queryString ? `${initialUrl}?${queryString}` : initialUrl

    window.history.replaceState(null, '', finalUrl)

    if (photographs.length === 1) {
      store.fetchEventPhotographs(true)
    }
    PhotographUtils.mantainScrollPositionAfterClosingModal(photographToDisplayId, photographs)
  }
  const handleAddBurst = (burst: Photograph[]) => {
    cartStore.addEventBurst(event, albums, burst)
  }

  const handleBuyBurst = (burst: Photograph[]) => {
    Tracker.addPackagePaymentInformation(photographs, event)
    return cartStore
      .createBurstPurchase(event.currency, albums, burst, event)
      .then((purchase: any) => {
        if (!isNil(purchase)) {
          window.location.href = purchase.paymentUrl!
        }
      })
  }

  return (
    <div>
      {store.albumsView ? (
        <div className="mx-4 md:mx-0">
          <InfiniteScroll
            dataLength={store.albums.length}
            next={() => store.fetchNextPage()}
            hasMore={store.hasMoreAlbumPages()}
            loader={<></>}
            scrollThreshold={0.95}
            className="overflow-hidden"
          >
            <div className="mt-2">
              {albums.length > 0 && <AlbumCards albums={store.albums} eventAlbumsView={true} />}
            </div>
          </InfiniteScroll>
        </div>
      ) : (
        photographs.length > 0 && (
          <InfiniteScroll
            dataLength={photographs.length}
            next={() => store.fetchNextPage()}
            hasMore={store.hasMorePages()}
            loader={<></>}
            scrollThreshold={0.95}
            className="overflow-hidden"
          >
            <div className="w-full min-h-primaryMobile bg-white rounded-lg mt-3 p-2">
              {event.isFree ? (
                <BatchedMasonryGallery
                  photographs={photographs}
                  setPhotograph={setIntervalAccordionPhotographModalPhotograph}
                  downloadFreePhotograph={handleDownloadFreePhotograph}
                />
              ) : (
                <>
                  {burstPurchaseFeatureFlag && !isNil(event.burstPrice) && (
                    <div className="px-1.5 w-full flex justify-between items-center">
                      <Checkbox
                        id="burst-mode"
                        label={t('View bursts')}
                        value={isBurstMode}
                        onChange={handleBurstModeChange}
                      />
                    </div>
                  )}
                  <div className="w-full grid grid-cols-6 gap-2 mt-2 px-1.5">
                    {Array.from(photographBlocksByBurst.entries()).map(
                      ([burstKey, burstPhotographs], index) => (
                        <div
                          key={burstKey}
                          className={`col-span-6 ${
                            isBurstMode && 'rounded-md border border-lumepic-medium_grey'
                          }`}
                        >
                          {isBurstMode && (
                            <div className="w-full flex justify-between items-center py-3 px-2 bg-bg_details rounded-t-md">
                              <h6 className="font-medium">
                                {t('Burst {{number}}', { number: index + 1 })}
                              </h6>
                              {burstPurchaseFeatureFlag &&
                                !isNil(store.event.burstPrice) &&
                                (() => {
                                  const totalPrice =
                                    calculateTotalPhotographsPrice(burstPhotographs)
                                  const discountPercentage = getQtyDiscountPercentageEarned(
                                    store.event.quantityDiscount ?? null,
                                    burstPhotographs
                                  )
                                  const priceAfterDiscount =
                                    totalPrice * (1 - discountPercentage / 100)

                                  return priceAfterDiscount > store.event.burstPrice! ? (
                                    <BurstButton
                                      handleAddBurstClick={handleAddBurst}
                                      handleBuyBurst={handleBuyBurst}
                                      burstPhotographs={burstPhotographs}
                                      eventDetailsStore={store}
                                    />
                                  ) : null
                                })()}
                            </div>
                          )}
                          <div className={`grid grid-cols-6 gap-2 ${isBurstMode && 'p-1.5'}`}>
                            {burstPhotographs.map((photograph) => {
                              const album = !isNil(store)
                                ? albums.find((album) => album.id === photograph.albumId)
                                : null
                              return (
                                <DetailsPhotographCard
                                  photograph={photograph}
                                  album={album!}
                                  eventDetailsStore={store}
                                  setPhotograph={setIntervalAccordionPhotographModalPhotograph}
                                  key={photograph.id}
                                  downloadFreePhotograph={handleDownloadFreePhotograph}
                                />
                              )
                            })}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </>
              )}
            </div>
          </InfiniteScroll>
        )
      )}

      {!albums.length &&
        !photographs.length &&
        !isLoadingEventDetails &&
        !isLoadingEventPhotographs &&
        !isLoadingEventAlbums && (
          <NoResultsFound
            emptyEvent={!searchInProgress}
            filteredSearchEmpty={searchInProgress}
            event={event}
          />
        )}

      {!isNil(photographToDisplayId) && (
        <PhotographModal
          photographToDisplayId={photographToDisplayId}
          setPhotographToDisplayId={setPhotograph}
          album={findAlbumForPhotograph(photographToDisplayId)!}
          photographs={photographs}
          loadingNextPage={isLoadingEventPhotographs}
          onClose={closeImageModal}
          onFeed
          onDownloadFreePhotograph={handleDownloadFreePhotograph}
        />
      )}
    </div>
  )
}
export default observer(EventDetailsContent)
