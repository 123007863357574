import { Skeleton } from '@mui/material'
import React from 'react'
import Masonry from '@mui/lab/Masonry'

const SkeletonLoaderMasonryGallery = () => {
  return (
    <Masonry columns={{ xs: 2, sm: 3, md: 4 }} spacing={0.5} className="w-full">
      {[...Array(18)].map((_, index) => (
        <div key={index} className="w-full overflow-hidden flex flex-col gap-2">
          <Skeleton
            variant="rounded"
            height={index % 2 === 0 ? 200 : 250}
            className="min-w-[150px]"
          />
        </div>
      ))}
    </Masonry>
  )
}

export default SkeletonLoaderMasonryGallery
