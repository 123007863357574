const prefix = '/feed'
export const FEED_ALBUMS = `${prefix}/albums`
export const TOP_10_LIKED_ALBUMS = `${prefix}/top-liked-albums`
export const FEED_ALBUM_DETAIL = `${prefix}/albums/:id`
export const FEED_ALBUM_PHOTOGRAPH_DETAIL = `${prefix}/albums/:id/:photographId`
export const FEED_EVENT_DETAILS = `/events/:landingPath`

export const feedAlbumDetailById = (albumId: string) => {
  return `${prefix}/albums/${albumId}`
}

export const feedAlbumPhotographDetailById = (albumId: string, photographId: string) => {
  return `${prefix}/albums/${albumId}/${photographId}`
}

export const feedEventDetailsByLandingPath = (landingPath: string) => {
  return `/events/${landingPath}`
}

export const feedEventDetailsByLandingPathAndOwnerId = (landingPath: string, ownerId: string) => {
  return `/events/${landingPath}?ownerId=${ownerId}`
}
