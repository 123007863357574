import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faWarning } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { formatPhotographCountText } from '../../../shared/utility'
import { observer } from 'mobx-react'
import Button from 'components/UI/Button'

type ItemDetailsSearchResultMessageProps = {
  searchInProgress: boolean
  tagValueLength: number
  isFilteringByFaceRecognition: boolean
  isLoadingEventPhotographs: boolean
  photographCount: number
  ownerIdFilterApplied?: boolean
  handleSeeAllPhotos?: () => void
  ownerIdAlias?: string
}

const ItemDetailsSearchResultMessage = ({
  searchInProgress,
  tagValueLength,
  isFilteringByFaceRecognition,
  isLoadingEventPhotographs,
  photographCount,
  ownerIdFilterApplied,
  handleSeeAllPhotos,
  ownerIdAlias,
}: ItemDetailsSearchResultMessageProps) => {
  const { t } = useTranslation()

  if (
    !searchInProgress ||
    !(tagValueLength > 0 || isFilteringByFaceRecognition) ||
    isLoadingEventPhotographs
  ) {
    return null
  }

  return (
    <>
      {photographCount > 0 ? (
        <div className="w-full flex flex-wrap md:flex-row md:justify-between items-start gap-4 p-3 rounded-lg bg-white/50 shadow-sm">
          <span className="flex items-center text-lumepic-black md:text-lg font-semibold">
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="text-lumepic-success_dark_green mr-2 text-lg"
            />
            {t('You’ve appeared in') + ' ' + formatPhotographCountText(photographCount, t)}!
          </span>
          {ownerIdFilterApplied && (
            <div className="flex flex-col items-start md:items-end gap-1">
              <span className="flex flex-wrap text-lumepic-black/80 md:text-base font-medium">
                {t('Showing only photos of the photographer: ')}
                <span className="font-bold ml-1">@{ownerIdAlias}</span>
              </span>
              <span className="flex-wrap text-lumepic-black/80 md:text-base font-medium">
                {t('You may appear in more photos in this event')}
              </span>
              <div className="w-full flex justify-center md:justify-end">
                <Button
                  btnType="PrimaryAction"
                  onClick={handleSeeAllPhotos || (() => {})}
                  extraStyle="text-sm w-fit"
                >
                  {t('See all my photos')}
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <span className="text-lumepic-black md:text-lg align-center md:font-medium ml-2 pt-2 block">
          <FontAwesomeIcon icon={faWarning} className="text-lumepic-grey mr-1 mt-2 lg:mt-3" />
          {t('You’ve not appeared in any photos')}
        </span>
      )}
    </>
  )
}

export default observer(ItemDetailsSearchResultMessage)
