import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from './AuthStore'
import PurchaseService from '../services/PurchaseService'
import Cart, { TagPackageType } from '../shared/models/Cart/Cart'
import Album from '../shared/models/Album'
import { Event } from '../shared/models/Event'
import { Photograph } from '../shared/models/Photograph'
import { isNil } from 'lodash'
import { CodeDiscount } from '../shared/models/CodeDiscount'
import { Purchase } from '../shared/models/Purchase'
import DiscountService from '../services/DiscountService'
import { toast } from 'react-toastify'
import CartService from '../services/CartService'
import FeedService, { FeedOrderFields, FeedOrderSorts } from 'services/FeedService'
import i18n from 'i18next'
import { CartLine, CartLineType } from 'shared/models/Cart/CartLine/CartLine'
import { Currency } from '../shared/util/Currency'
import { PhotographCartLine } from '../shared/models/Cart/CartLine/PhotographCartLine'
import { PackageCartLine } from '../shared/models/Cart/CartLine/PackageCartLine'
import { PurchaseBuilder } from '../builders/PurchaseBuilder'
import CreatePurchaseInput from 'services/Interfaces/Purchase/PurchaseRequest.interface'
import { EventService } from 'services/EventService'
import { TagKeys } from 'services/RequestInterfaces/Tag/Tag.interface'

const LIMIT = 20
const SKIP = 0

const PHOTOS_PER_PAGE = 10

interface GroupedCartLine {
  key: string
  lines: CartLine[]
  title: string
  details: string
  isEventLine: boolean
  subtotalPrice: number
  totalPrice: number
}

export default class CartStore {
  private cart: Cart
  public albums: Album[] = []
  public packagePhotographs: Photograph[] = []
  public page: number
  public packagePhotographCount: number
  public isLoadingPackagePhotographs: boolean
  public isLoading: boolean
  public isLoadingDiscount: boolean
  public discountErrorMessage: string | null
  public displayMiniCart: boolean
  public printedPhotographs: boolean
  private tagKey?: TagKeys
  private tagIds: string[] = []

  private readonly ARG_TAXES_CHARGE = 0.6

  private currentAlbumId?: string
  private currentEventId?: string
  private currentPassword?: string

  constructor(
    private readonly authStore: AuthStore,
    private readonly purchaseService: PurchaseService,
    private readonly discountService: DiscountService,
    private readonly cartService: CartService,
    private readonly feedService: FeedService,
    private readonly eventService: EventService
  ) {
    this.resetStore()
    makeAutoObservable(this)
    this.fetchCart()
    this.fetchCartAlbums()
  }

  public setDisplayMiniCart(display: boolean) {
    this.displayMiniCart = display
  }

  private resetStore() {
    this.startLoading()
    this.cart = new Cart()
    this.albums = []
    this.packagePhotographs = []
    this.page = 1
    this.packagePhotographCount = 0
    this.isLoading = false
    this.isLoadingPackagePhotographs = false
    this.displayMiniCart = false
    this.printedPhotographs = false
    this.discountErrorMessage = null
    this.tagIds = []

    this.stopLoading()
  }

  public async fetchCartAlbums() {
    const nonPackageAlbums = this.cart.lines
      .filter((line) => line.type === CartLineType.Photograph)
      .map((line) => {
        return (line as PhotographCartLine).album
      })
    const packageAlbums = this.cart.lines
      .filter((line) => line.type === CartLineType.Package)
      .map((line) => {
        return (line as PackageCartLine).albums
      })
      .flat()

    const uniqueAlbumIds = [
      ...new Set([...nonPackageAlbums, ...packageAlbums].map((album) => album.id)),
    ]

    if (uniqueAlbumIds.length > 0) {
      try {
        const response = await this.feedService.fetchFeedAlbums({
          limit: LIMIT,
          offset: SKIP,
          order: {
            field: FeedOrderFields.TAKEN_DATE,
            sort: FeedOrderSorts.DESC,
          },
          albumIds: uniqueAlbumIds,
          includeHiddenEventAlbums: true,
        })
        runInAction(() => {
          this.albums = response.albums
        })
        this.stopLoading()
      } catch (error: any) {
        this.stopLoading()
      }
    } else {
      return
    }
  }

  public addPhotograph(photograph: Photograph, album: Album, persistCart = true) {
    this.startLoading()
    if (!isNil(this.cart.currency) && this.cart.currency !== album.currency) {
      toast.error(() => i18n.t('You cannot mix currencies in the same cart'))
      this.stopLoading()
    } else {
      this.cart = this.cartService.addPhotograph(this.cart, photograph, album, album.event)
      this.cart.currency = album.currency
      if (persistCart) {
        this.persistCart(true)
      }
      this.stopLoading()
    }
  }

  public addAlbumBurst(album: Album, photographs: Photograph[], persistCart = true) {
    this.startLoading()
    this.handleExistingPackagePhotos(photographs)
    const currency = album.currency
    if (!isNil(this.cart.currency) && this.cart.currency !== currency) {
      toast.error(() => i18n.t('You cannot mix currencies in the same cart'))
      this.stopLoading()
    } else {
      this.startLoading()
      this.cart = this.cartService.addAlbumBurst(this.cart, album, photographs)
      this.cart.currency = currency
      if (persistCart) {
        this.persistCart(true)
      }
      this.stopLoading()
    }
  }

  public addEventBurst(
    event: Event,
    albums: Album[],
    photographs: Photograph[],
    persistCart = true
  ) {
    this.startLoading()
    this.handleExistingPackagePhotos(photographs)
    const currency = event.currency
    if (!isNil(this.cart.currency) && this.cart.currency !== currency) {
      toast.error(() => i18n.t('You cannot mix currencies in the same cart'))
      this.stopLoading()
    } else {
      this.cart = this.cartService.addEventBurst(this.cart, event, albums, photographs)
      this.cart.currency = currency
      if (persistCart) {
        this.persistCart(true)
      }
      this.stopLoading()
    }
  }

  public addAlbumTagPackage(
    album: Album,
    photographs: Photograph[],
    quantityOfPhotographs: number,
    packageType: TagPackageType,
    tagId: string,
    persistCart = true
  ) {
    //TODO: If there are already photographs in the cart that are included in the package, remove those and add the package.
    this.startLoading()
    const currency = album.currency
    if (!isNil(this.cart.currency) && this.cart.currency !== currency) {
      toast.error(() => i18n.t('You cannot mix currencies in the same cart'))
      this.stopLoading()
    } else {
      this.cart = this.cartService.addAlbumTagPackage(
        this.cart,
        album,
        photographs,
        quantityOfPhotographs,
        packageType,
        tagId
      )
      this.cart.currency = currency
      if (persistCart) {
        this.persistCart(true)
      }
      this.stopLoading()
    }
  }

  public addTagEventPackage(
    event: Event,
    albums: Album[],
    photographs: Photograph[],
    quantityOfPhotographs: number,
    packageType: TagPackageType,
    tagId: string,
    persistCart = true
  ) {
    this.startLoading()
    const currency = event.currency
    if (!isNil(this.cart.currency) && this.cart.currency !== currency) {
      toast.error(() => i18n.t('You cannot mix currencies in the same cart'))
      this.stopLoading()
    } else {
      this.cart = this.cartService.addEventTagPackage(
        this.cart,
        event,
        albums,
        photographs,
        quantityOfPhotographs,
        packageType,
        tagId
      )
      this.cart.currency = currency
      if (persistCart) {
        this.persistCart(true)
      }
      this.stopLoading()
    }
  }

  public removePhotograph(photographId: string) {
    this.startLoading()
    this.cart = this.cartService.removePhotograph(this.cart, photographId)
    if (this.isEmpty() && !isNil(this.cart.currency)) {
      this.resetCurrency()
    }
    this.persistCart(true)
    this.stopLoading()
  }

  public removeLine(line: CartLine) {
    this.startLoading()
    this.cart = this.cartService.removeLine(this.cart, line)
    if (this.isEmpty() && !isNil(this.cart.currency)) {
      this.resetCurrency()
    }
    this.persistCart(true)
    this.stopLoading()
  }

  public removePhotographs(photographIds: string[]) {
    this.startLoading()
    this.cart = this.cartService.removePhotographs(this.cart, photographIds)
    if (this.isEmpty() && !isNil(this.cart.currency)) {
      this.resetCurrency()
    }
    this.persistCart(true)
    this.stopLoading()
  }

  private async persistCart(updateFirebaseCart?: boolean) {
    const userId = this.authStore.isAuthenticated() ? this.authStore.getLoggedUser().id : null
    await this.cartService.persistCart(this.cart, userId, updateFirebaseCart)
  }

  public async fetchCart() {
    const userId = this.authStore.isAuthenticated() ? this.authStore.getLoggedUser().id : null
    this.cart = await this.cartService.syncCart(this.cart, userId)
    this.persistCart()
  }

  async fetchNextPage(): Promise<void> {
    this.setPage(this.page + 1)
    await this.fetchPackagePhotographs(
      this.tagIds[0],
      this.currentAlbumId,
      this.currentEventId,
      this.currentPassword
    )
  }

  async fetchPackagePhotographs(
    tagId: string,
    albumId?: string,
    eventId?: string,
    password?: string
  ): Promise<void> {
    this.isLoadingPackagePhotographs = true
    if (this.page === 1) {
      this.packagePhotographs = []
      this.tagIds = [tagId]
    }

    this.currentAlbumId = albumId
    this.currentEventId = eventId
    this.currentPassword = password

    try {
      const response = isNil(eventId)
        ? await this.feedService.fetchAlbumPhotographs(
            albumId!,
            PHOTOS_PER_PAGE,
            (this.page - 1) * PHOTOS_PER_PAGE,
            tagId,
            this.tagKey,
            undefined,
            undefined,
            undefined,
            undefined,
            password
          )
        : await this.eventService.fetchEventPhotographs(
            eventId,
            PHOTOS_PER_PAGE,
            (this.page - 1) * PHOTOS_PER_PAGE,
            tagId,
            this.tagKey,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            password
          )

      runInAction(() => {
        this.packagePhotographs =
          this.page === 1 ? response.items : [...this.packagePhotographs, ...response.items]
        this.packagePhotographCount = response.count
        this.isLoadingPackagePhotographs = false
      })
    } catch (error) {
      runInAction(() => {
        this.isLoadingPackagePhotographs = false
      })
      throw error
    }
  }

  hasMorePages(): boolean {
    const pageCount = Math.ceil(this.packagePhotographCount / PHOTOS_PER_PAGE)
    return this.page < pageCount
  }

  setPage(page: number) {
    if (page < 1) {
      throw Error(`Page number can't be less than 1`)
    }
    this.page = page
  }

  public alreadyInCart(photograph: Photograph): boolean {
    return this.cart.alreadyInCart(photograph)
  }

  public alreadyInPackage(photograph: Photograph): boolean {
    return this.cart.alreadyInPackage(photograph)
  }

  //DISCOUNTS
  public async applyPurchaseCodeDiscount(code: string, photographIds: string[]): Promise<void> {
    if (isNil(code) || code.length === 0) {
      toast.error('Invalid discount code')
    } else if (!this.discountAlreadyInCart(code)) {
      this.startLoadingDiscount()
      try {
        const [discount] = await this.discountService.findForPurchase(
          code,
          photographIds,
          this.authStore.getToken()
        )
        if (!isNil(discount) && !isNil(discount.code)) {
          runInAction(() => {
            this.stopLoadingDiscount()
            this.addDiscountToCart(discount)
          })
        } else {
          this.stopLoadingDiscount()
        }
      } catch (e: any) {
        this.stopLoadingDiscount()
      }
    } else {
      toast.error(i18n.t('Discount already applied'))
    }
  }

  private addDiscountToCart(discount: CodeDiscount) {
    const discountAlreadyApplied = this.discountAlreadyInCart(discount.code)
    if (!discountAlreadyApplied) {
      this.cart.codeDiscounts.push(discount)
    }
    this.persistCart()
  }

  private discountAlreadyInCart(discountCode: string) {
    const foundDiscount = this.cart.codeDiscounts.find((discount) => discount.code === discountCode)
    return !isNil(foundDiscount)
  }

  public createPurchase = async (cart: Cart): Promise<Purchase | undefined> => {
    this.startLoading()
    try {
      const purchaseData: CreatePurchaseInput = PurchaseBuilder.BuildPurchaseFromCart(
        cart,
        this.printedPhotographs
      )
      const purchase = await this.purchaseService.purchase(purchaseData, this.authStore.getToken())

      runInAction(() => {
        this.stopLoading()
      })

      return purchase
    } catch (error: any) {
      this.stopLoading()
    }
  }

  public createTagPackagePurchase = async (
    tagId: string,
    currency: Currency,
    albums?: Album[],
    event?: Event,
    photographIds?: string[]
  ): Promise<Purchase | undefined> => {
    this.startLoading()
    try {
      const purchaseData = PurchaseBuilder.BuildTagPackagePurchase(
        tagId,
        currency,
        albums,
        event,
        [],
        photographIds
      )
      return await this.purchaseService.purchase(purchaseData, this.authStore.getToken())
    } catch (error: any) {
      this.stopLoading()
    }
  }

  public createBurstPurchase = async (
    currency: Currency,
    albums: Album[],
    photographs: Photograph[],
    event?: Event
  ): Promise<Purchase | undefined> => {
    this.startLoading()
    try {
      const photographIds = photographs.map((photo) => photo.id)
      const purchaseData = PurchaseBuilder.BuildBurstPurchase(
        this.cartService.calculateBurstTimestamp(photographs),
        currency,
        albums,
        event,
        [],
        photographIds
      )
      return await this.purchaseService.purchase(purchaseData, this.authStore.getToken())
    } catch (error: any) {
      this.stopLoading()
    }
  }

  private startLoading = () => {
    this.isLoading = true
  }

  private stopLoading = () => {
    this.isLoading = false
  }

  private startLoadingDiscount = () => {
    this.isLoadingDiscount = true
  }

  private stopLoadingDiscount = () => {
    this.isLoadingDiscount = false
  }

  public handleChangePrintedPhotographs = () => {
    this.printedPhotographs = !this.printedPhotographs
  }

  public getCart(): Cart {
    return this.cart
  }

  /**
   * @returns the cart lines sorted by album
   */
  public getLines(): Array<CartLine> {
    return this.cart.lines
  }

  public getItemCount(): number {
    const cartLines = Array.from(this.cart.lines.values())
    return cartLines.length
  }

  public isEmpty(): boolean {
    const cartLines = this.getLines()
    return cartLines.length === 0
  }

  public groupForDisplay(): GroupedCartLine[] {
    const cartLines = this.getLines()

    const groupedLines = cartLines.reduce((acc, line) => {
      const key = line.getGroupKey()
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(line)
      return acc
    }, {} as { [key: string]: CartLine[] })

    return Object.entries(groupedLines).map(([key, lines]) => {
      const firstLine = lines[0]
      const castedLine =
        firstLine.type === CartLineType.Package
          ? (firstLine as PackageCartLine)
          : (firstLine as PhotographCartLine)

      return {
        key,
        lines,
        title: firstLine.getTitle(),
        details: firstLine.getDetails(),
        isEventLine: !isNil(castedLine.event?.id), // Check for event.id instead of just event because for PhotographCartLines the event is not nil, it has only an empty array of Tag
        subtotalPrice: lines.reduce((total, line) => total + line.subtotalPrice, 0),
        totalPrice: lines.reduce((total, line) => total + line.totalPrice, 0),
      }
    })
  }

  resetCurrency() {
    runInAction(() => {
      this.cart.currency = undefined
    })
  }

  async deletePurchasedCart() {
    this.startLoading()
    await this.fetchCart()
    this.cart.reset()
    this.persistCart()
    this.stopLoading()
  }

  private handleExistingPackagePhotos(photographs: Photograph[]): void {
    const packagePhotosInCart = photographs.filter((photograph) => this.alreadyInCart(photograph))
    const packagePhotosInCartIds = packagePhotosInCart.map((ph) => ph.id)

    if (packagePhotosInCartIds.length > 0) {
      this.removePhotographs(packagePhotosInCartIds)
    }
  }

  public getArgTaxesCharge(): number {
    return this.cart.totalPrice * this.ARG_TAXES_CHARGE
  }

  public getTotalWithArgTaxes(): number {
    return this.cart.totalPrice * (1 + this.ARG_TAXES_CHARGE)
  }
}
