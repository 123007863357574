import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import storesContext from 'providers/storesContext'
import { isNil } from 'lodash'

const getAlbumValidationSchema = () => {
  const { t } = useTranslation()
  const { featureFlagsStore } = useContext(storesContext)!

  const photographMinimumPrice = featureFlagsStore.photographMinimumPriceForCurrency
    ? featureFlagsStore.photographMinimumPriceForCurrency
    : 1

  const today = new Date()

  return yup.object({
    description: yup.string().required(t('Description is required') as string),
    currency: yup.string().required(t('Currency is required') as string),
    defaultImagePrice: yup
      .number()
      .min(
        photographMinimumPrice,
        t('Must be greater than {{price}}', { price: photographMinimumPrice }) as string
      )
      .required(t('Default image price is required') as string),
    defaultPackagePrice: yup
      .number()
      .optional()
      .nullable()
      .min(1, t('Must be greater than 1') as string)
      .test({
        name: 'price-comparison',
        message: t('Default package price must be greater than default image price') as string,
        test: function (defaultPackagePrice, { parent }) {
          return isNil(defaultPackagePrice) || defaultPackagePrice > parent.defaultImagePrice
        },
      }),
    burstPrice: yup
      .number()
      .optional()
      .nullable()
      .min(1, t('Must be greater than 1') as string)
      .test({
        name: 'price-comparison',
        message: t('Burst price must be greater than default image price') as string,
        test: function (burstPrice, { parent }) {
          return isNil(burstPrice) || burstPrice > parent.defaultImagePrice
        },
      }),
    takenDate: yup
      .date()
      .max(today, t('Date must not be in the future') as string)
      .required(t('Date is required') as string),
    activityId: yup
      .string()
      .uuid()
      .required(t('Activity is required') as string),
    locationId: yup
      .string()
      .uuid()
      .required(t('Location is required') as string),
    eventId: yup.string().optional().uuid(),
    password: yup
      .string()
      .optional()
      .min(6, t('Password must have at least 6 characters') as string),
  })
}

export default getAlbumValidationSchema
