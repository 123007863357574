import React from 'react'
import lumepicLogo from '../../assets/icons/v2/lumepic-logo.svg'
import logoAnde from '../../assets/images/Ande.svg'
import logoANII from '../../assets/images/ANII.svg'
import logoCIE from '../../assets/images/CIE.svg'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { useTranslation } from 'react-i18next'
import { CONTACT_US, FAQ, PRIVACY_POLICIES, TERMS_AND_CONDITIONS } from 'routing/Paths'

const Footer = () => {
  const { t } = useTranslation()

  const getCurrentYear = () => {
    const currentDate = new Date()
    return currentDate.getFullYear()
  }

  return (
    <footer className="bg-bg_section_titles py-[50px] z-20 relative md:py-[35px]">
      <div className="container mx-auto">
        <div className="w-full flex flex-col md:flex-row items-center justify-between pb-[30px] mb-[30px] border-b border-lumepic-medium_grey">
          <div className="w-full md:w-1/2 flex justify-center md:justify-start">
            <img
              loading="lazy"
              src={lumepicLogo}
              alt="Lumepic Logo"
              className="w-full max-w-[150px] max-h-[55px] mb-5 md:mb-0"
            />
          </div>
          <div className="w-full md:w-1/2 flex justify-center md:justify-end">
            <p className="text-lumepicSm m-0">{`© ${getCurrentYear()} Lumepic`}</p>
          </div>
        </div>

        <div className="w-full pb-[30px] mb-[30px] border-b border-lumepic-medium_grey">
          <div className="hidden md:flex md:justify-center md:items-center md:gap-8">
            <img
              loading="lazy"
              src={logoAnde}
              alt="Ande Logo"
              className="max-w-[150px] max-h-[55px]"
            />
            <img
              loading="lazy"
              src={logoANII}
              alt="ANII Logo"
              className="max-w-[200px] max-h-[95px]"
            />
            <img
              loading="lazy"
              src={logoCIE}
              alt="CIE Logo"
              className="max-w-[200px] max-h-[95px]"
            />
          </div>

          <div className="flex flex-col items-center gap-4 md:hidden">
            <img
              loading="lazy"
              src={logoAnde}
              alt="Ande Logo"
              className="max-w-[150px] max-h-[55px]"
            />
            <img
              loading="lazy"
              src={logoANII}
              alt="ANII Logo"
              className="max-w-[150px] max-h-[65px]"
            />
            <img
              loading="lazy"
              src={logoCIE}
              alt="CIE Logo"
              className="max-w-[150px] max-h-[65px]"
            />
          </div>
        </div>

        <div>
          <div className="flex flex-col items-center justify-between md:flex-row">
            <div className="flex flex-row mb-[15px] md:mb-0">
              <a
                href="https://www.facebook.com/profile.php?id=100089489783866"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center rounded-lg w-8 h-8 bg-[rgba(225,225,225,0.2)] hover:bg-lumepic-medium_grey ease-in-out duration-200 border border-lumepic-medium_grey"
              >
                <FontAwesomeIcon icon={faFacebookF} className="text-lumepic-dark_grey text-xl" />
              </a>
              <a
                href="https://www.instagram.com/lume.pic"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center rounded-lg w-8 h-8 mx-1 bg-[rgba(225,225,225,0.2)] hover:bg-lumepic-medium_grey ease-in-out duration-200 border border-lumepic-medium_grey"
              >
                <FontAwesomeIcon icon={faInstagram} className="text-lumepic-dark_grey text-2xl" />
              </a>
              <a
                href="https://www.tiktok.com/@spotted.uy"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center rounded-lg w-8 h-8 bg-[rgba(225,225,225,0.2)] hover:bg-lumepic-medium_grey ease-in-out duration-200 border border-lumepic-medium_grey"
              >
                <FontAwesomeIcon icon={faTiktok} className="text-lumepic-dark_grey text-xl" />
              </a>
            </div>

            <div className="flex flex-col items-center mt-[5px] md:flex-row md:mt-0">
              <NavLink
                className="text-lumepicSm text-lumepic-grey hover:text-lumepic-black hover:no-underline transition-all duration-200 ease-in-out my-0.5 flex justify-center text-center"
                to={FAQ}
              >
                {t('Frequently Asked Questions')}
              </NavLink>
              <NavLink
                className="text-lumepicSm text-lumepic-grey hover:text-lumepic-black hover:no-underline transition-all duration-200 ease-in-out md:ml-5 my-0.5 flex justify-center text-center"
                to={TERMS_AND_CONDITIONS}
              >
                {t('Terms and Conditions')}
              </NavLink>
              <NavLink
                className="text-lumepicSm text-lumepic-grey hover:text-lumepic-black hover:no-underline transition-all duration-200 ease-in-out md:ml-5 my-0.5 flex justify-center text-center"
                to={PRIVACY_POLICIES}
              >
                {t('Privacy Policies')}
              </NavLink>
              <NavLink
                className="text-lumepicSm text-lumepic-grey hover:text-lumepic-black hover:no-underline transition-all duration-200 ease-in-out md:ml-5 my-0.5 flex justify-center text-center"
                to={CONTACT_US}
              >
                {t('Contact us')}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
