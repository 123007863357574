import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUpRightFromSquare,
  faCalendar,
  faInfo,
  faLocationDot,
  faBolt,
} from '@fortawesome/free-solid-svg-icons'
import photographerIcon from '../../../assets/icons/v2/photographer.svg'
import Album from 'shared/models/Album'
import { Event } from 'shared/models/Event'
import { formatUtcToLocaleDate, formatDateShort, getFeatureFlagValue } from 'shared/utility'
import Button from 'components/UI/Button'
import { FeatureFlags } from '../../../config/constants/featureFlags'
import storesContext from 'providers/storesContext'
import * as paths from '../../../routing/Paths'
import { useMediaQuery, useTheme } from '@mui/material'
import QuantityDiscount from 'components/AlbumDetails/components/QuantityDiscount'
import EventInformationSkeleton from 'containers/Events/EventDetails/components/EventInformationSkeleton'
import { isNil } from 'lodash'

type ItemDetailsInformationProps = {
  album?: Album
  event?: Event
  albumsCount?: number
  photographCount?: number
  isLoading?: boolean
  filtersView?: boolean
}

const ItemDetailsInformation = ({
  album,
  event,
  albumsCount,
  photographCount,
  isLoading,
  filtersView = false,
}: ItemDetailsInformationProps) => {
  const { t } = useTranslation()
  const { featureFlagsStore } = useContext(storesContext)!
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.up('md'))

  const photographerProfileEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.PHOTOGRAPHER_PROFILE,
    true
  )

  const openPhotographerProfile = (alias: string) => {
    const photographerProfileUrl = paths.photographerProfileByAlias(alias)
    window.open(photographerProfileUrl, '_blank')
  }

  if (isLoading) {
    return <EventInformationSkeleton />
  }

  const isAlbum = !!album
  const isEvent = !!event

  const itemAttributeClass = 'flex items-center gap-1.5'
  const itemAttributeLabelClass = 'text-base text-lumepic-grey'

  return (
    <div className="w-full flex flex-col rounded-lg lg:flex-row lg:justify-between items-center md:bg-white">
      <div className="flex gap-2 md:gap-4 rounded-md px-2 py-2.5 w-full md:bg-white">
        {isEvent && event.coverPhotographSrc && (
          <img
            src={event.coverPhotographSrc}
            alt="event-cover"
            className="object-cover object-center rounded-md"
            style={{ height: '150px', maxWidth: '150px' }}
          />
        )}
        <div className="flex flex-col gap-2">
          <p className="font-semibold text-base">{isEvent && event.name?.toUpperCase()}</p>
          {isAlbum && (
            <p className="flex items-center gap-2 text-base text-lumepic-black">
              <FontAwesomeIcon className="text-xl text-lumepic-black ml-1" icon={faLocationDot} />
              {album.location.spotName}
            </p>
          )}
          <p className={itemAttributeClass}>
            <FontAwesomeIcon
              className={`text-xl text-lumepic-grey ${isAlbum && 'pl-1'}`}
              icon={faCalendar}
            />
            <span className={itemAttributeLabelClass}>
              {isEvent
                ? event.dateTo
                  ? formatDateShort(event.date) + ' - ' + formatDateShort(event.dateTo)
                  : formatDateShort(event.date)
                : isAlbum && album.takenDate
                ? formatUtcToLocaleDate(album.takenDate)
                : null}
            </span>
          </p>

          {isEvent && (
            <p className={itemAttributeClass}>
              <FontAwesomeIcon icon={faLocationDot} className="text-xl text-lumepic-grey" />
              <span className={itemAttributeLabelClass}>{isEvent && event.location?.spotName}</span>
            </p>
          )}
          {isEvent && event.activity?.name && (
            <p className={itemAttributeClass}>
              <FontAwesomeIcon icon={faBolt} className="text-xl text-lumepic-grey" />
              <span className={itemAttributeLabelClass}>{event.activity.name}</span>
            </p>
          )}
          {isAlbum && (
            <>
              <p className="flex items-center">
                <img
                  loading="lazy"
                  alt="Photographer"
                  src={photographerIcon}
                  className="mt-1 w-7 h-7"
                />
                <span className={itemAttributeLabelClass}>{album.owner.alias}</span>
                {photographerProfileEnabled && album.owner.alias && (
                  <>
                    <Button
                      btnType={'TertiaryAction'}
                      onClick={() => openPhotographerProfile(album.owner.alias)}
                      extraStyle="ml-3 mb-0 mt-1 md:mt-2"
                    >
                      {t('View profile')}
                      <FontAwesomeIcon
                        icon={faArrowUpRightFromSquare}
                        className="ml-1 text-lumepic-black"
                      />
                    </Button>
                  </>
                )}
              </p>
              <p className={`flex items-center ${isAlbum && 'pl-1'}`}>
                <div className="flex items-center justify-center w-5 h-5 mr-1 rounded-full bg-lumepic-grey">
                  <FontAwesomeIcon className="text-xs text-white" icon={faInfo} />
                </div>
                <p className="text-base text-lumepic-grey text-ellipsis">{album.description}</p>
              </p>
            </>
          )}
          {isEvent &&
            !isNil(albumsCount) &&
            !isNil(photographCount) &&
            albumsCount > 0 &&
            photographCount > 0 && (
              <div className={itemAttributeClass}>
                <div className="w-4 h-4 flex items-center justify-center bg-lumepic-grey text-xs text-white rounded-full">
                  {albumsCount ?? null}
                </div>
                <span className="text-sm text-lumepic-grey">
                  {albumsCount === 1 ? t('album') : t('albums')} {t('and')} {photographCount}{' '}
                  {photographCount === 1 ? t('photograph') : t('photographs')}.
                </span>
              </div>
            )}
        </div>
      </div>
      {((event && !filtersView) || (!event && album)) &&
        (!event?.isFree || album?.isFree) &&
        (event?.quantityDiscount || album?.quantityDiscount) && (
          <>
            {isMdScreen && (
              <div className="w-full lg:w-2/5">
                <QuantityDiscount album={album} event={event} />
              </div>
            )}
          </>
        )}
    </div>
  )
}

export default observer(ItemDetailsInformation)
