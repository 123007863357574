import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import SearchBarDropdown, {
  DropdownOption,
} from 'components/UI/SearchBar/components/SearchBarDropdown'
import bibIcon from '../../../assets/icons/v2/bibIcon.svg'
import selfieIcon from '../../../assets/icons/v2/selfieIcon.svg'
import calendarIcon from '../../../assets/icons/v2/calendarIcon.svg'
import clockIcon from '../../../assets/icons/v2/clockIcon.svg'
import EventDetailsStore from 'containers/Events/EventDetails/EventDetailsStore'
import AlbumDetailsStore from 'components/AlbumDetails/AlbumDetailsStore'

type ItemDetailsSearchBarProps = {
  eventDetailsStore?: EventDetailsStore
  albumDetailsStore?: AlbumDetailsStore
  handleOpenUploadSelfieModal: () => void
  handleOpenFilterByTagModal: () => void
  handleOpenFilterByDateAndTimeModal: () => void
  handleSearchByActivity?: () => void
  searchInProgress: boolean
  moreThanFiveDays: boolean
  days?: DropdownOption[]
}

const ItemDetailsSearchBar = ({
  eventDetailsStore,
  albumDetailsStore,
  handleOpenUploadSelfieModal,
  handleOpenFilterByTagModal,
  handleOpenFilterByDateAndTimeModal,
  handleSearchByActivity,
  searchInProgress,
  moreThanFiveDays,
  days,
}: ItemDetailsSearchBarProps) => {
  const { t } = useTranslation()

  const { tagValue, isFilteringByFaceRecognition, dateRange } =
    eventDetailsStore ?? albumDetailsStore ?? {}

  const customButtonClasses = `
  w-full md:w-auto
  flex
  items-center
  justify-center
  p-2
  rounded-full
  border
  border-gray-300
  shadow-sm
  hover:shadow-md
  transition-all
  duration-200
  ease-in-out
  hover:bg-lumepic-light_black 
  hover:text-white
  group
`

  const itemDate = eventDetailsStore?.event.date ?? albumDetailsStore?.album.takenDate
  const recognitionDateStart = new Date(2023, 10, 1)

  const showFaceRecognitionButton = itemDate && new Date(itemDate) > recognitionDateStart //TODO: add validation for processed album to show face rec button

  const handleChangeActivity = (option?: DropdownOption | null) => {
    eventDetailsStore?.setActivity(option?.label)
    handleSearchByActivity?.()
  }

  const availableActivities = eventDetailsStore?.isLoadingEventActivities
    ? []
    : Array.from(new Set(eventDetailsStore?.activities.map((activity) => activity.id))).map(
        (id) => {
          const activity = eventDetailsStore?.activities.find((a) => a.id === id)
          return {
            value: id,
            key: id,
            label: t(activity?.name ?? ''),
          }
        }
      )

  const isMultipleDay = !isNil(days) && days.length > 1

  return (
    <div className="flex flex-wrap gap-2 rounded-lg lg:pt-2">
      <div className="flex flex-col w-full md:flex-row gap-2">
        {!isNil(eventDetailsStore) && isNil(eventDetailsStore?.event.activityId) && (
          <div className="flex w-full md:w-1/6">
            <SearchBarDropdown
              options={availableActivities ?? []}
              selectedValue={
                !isNil(eventDetailsStore?.event.activity)
                  ? {
                      value: eventDetailsStore?.event.activity?.id ?? '',
                      label: eventDetailsStore?.event.activity.name ?? '',
                    }
                  : null
              }
              onChange={handleChangeActivity}
              dropdownLabel={t('Sport')}
              isLoading={eventDetailsStore?.isLoadingEventActivities}
            />
          </div>
        )}
        {showFaceRecognitionButton &&
          ((!isNil(eventDetailsStore) && eventDetailsStore.event.faceRecognitionFilter) ||
            (!isNil(albumDetailsStore) && albumDetailsStore.album.faceRecognitionProcessed)) && (
            <button
              onClick={handleOpenUploadSelfieModal}
              className={` ${customButtonClasses} ${
                isFilteringByFaceRecognition
                  ? 'bg-lumepic-light_black text-white'
                  : 'bg-white text-gray-700'
              }`}
            >
              <img
                src={selfieIcon}
                alt="selfie"
                className={`w-7 h-7 mr-2 group-hover:filter group-hover:invert ${
                  isFilteringByFaceRecognition ? 'filter invert' : ''
                }`}
              />
              {t('Face recognition')}
            </button>
          )}
        {((!isNil(eventDetailsStore) && eventDetailsStore.event.textFilter) ||
          (!isNil(albumDetailsStore) &&
            !isNil(albumDetailsStore.albumTags) &&
            albumDetailsStore.albumTags?.length > 0)) && (
          <button
            onClick={handleOpenFilterByTagModal}
            className={` ${customButtonClasses} ${
              (!isNil(eventDetailsStore) &&
                !isNil(tagValue) &&
                tagValue.value?.length > 0 &&
                searchInProgress) ||
              (!isNil(albumDetailsStore) &&
                !isNil(albumDetailsStore.tagIds) &&
                albumDetailsStore.tagIds.length > 0 &&
                searchInProgress)
                ? 'bg-lumepic-light_black text-white'
                : 'bg-white text-gray-700'
            }`}
          >
            <img
              src={bibIcon}
              alt="bib-icon"
              className={`w-7 h-7 mr-2 group-hover:filter group-hover:invert ${
                (!isNil(eventDetailsStore) &&
                  !isNil(tagValue) &&
                  tagValue.value?.length > 0 &&
                  searchInProgress) ||
                (!isNil(albumDetailsStore) &&
                  !isNil(albumDetailsStore.tagIds) &&
                  albumDetailsStore.tagIds.length > 0 &&
                  searchInProgress)
                  ? 'filter invert'
                  : ''
              }`}
            />
            {t('Text recognition')}
          </button>
        )}
        {!moreThanFiveDays && !isNil(days) && days.length > 0 && (
          <button
            onClick={handleOpenFilterByDateAndTimeModal}
            className={`${customButtonClasses} ${
              !isNil(dateRange?.[0]) || !isNil(dateRange?.[1])
                ? 'bg-lumepic-light_black text-white'
                : 'bg-white text-gray-700'
            }`}
          >
            {isMultipleDay ? (
              <>
                <img
                  src={calendarIcon}
                  alt="calendar-days"
                  className={`w-6 h-6 mr-2 group-hover:filter group-hover:invert ${
                    !isNil(dateRange?.[0]) || !isNil(dateRange?.[1]) ? 'filter invert' : ''
                  }`}
                />
                {t('Day and time')}
              </>
            ) : (
              !isNil(dateRange?.[0]) &&
              !isNil(dateRange?.[1]) && (
                <>
                  <img
                    src={clockIcon}
                    alt="clock"
                    className={`w-6 h-6 mr-2 group-hover:filter group-hover:invert ${
                      !isNil(dateRange?.[0]) || !isNil(dateRange?.[1]) ? 'filter invert' : ''
                    }`}
                  />
                  {t('Hour')}
                </>
              )
            )}
          </button>
        )}
      </div>
    </div>
  )
}

export default observer(ItemDetailsSearchBar)
