import * as yup from 'yup'
import i18next from 'i18next'

const getFreeEventAlbumValidationSchema = (eventStartDate: Date, eventEndDate?: Date | null) => {
  return yup.object({
    description: yup.string().required(i18next.t('Description is required') as string),
    takenDate: yup
      .date()
      .when('$takenDateNotNull', (takenDateNotNull, schema) => {
        return takenDateNotNull ? schema.required(i18next.t('Date is required') as string) : schema
      })
      .test(
        'within-event-range',
        i18next.t('Date must be within event date range') as string,
        function (value) {
          if (!value) return false
          const start = new Date(eventStartDate)
          const end = eventEndDate ? new Date(eventEndDate) : start
          return value >= start && value <= end
        }
      ),
    activityId: yup
      .string()
      .uuid()
      .when('$activityIdNotNull', (activityIdNotNull, schema) => {
        return activityIdNotNull
          ? schema.required(i18next.t('Activity is required') as string)
          : schema
      }),
    locationId: yup
      .string()
      .uuid()
      .when('$locationIdNotNull', (locationIdNotNull, schema) => {
        return locationIdNotNull
          ? schema.required(i18next.t('Location is required') as string)
          : schema
      }),
    eventId: yup
      .string()
      .uuid()
      .when('$eventIdNotNull', (eventIdNotNull, schema) => {
        return eventIdNotNull ? schema.required(i18next.t('Event is required') as string) : schema
      }),
  })
}

export default getFreeEventAlbumValidationSchema
