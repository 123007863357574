import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import Tracker from '../../shared/tracking'
import EventsStore from './EventsStore'
import { formatUtcToLocaleDate, SetPageTitle } from 'shared/utility'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import Spinner from 'components/UI/Spinner'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import StoresContext from '../../providers/storesContext'
import { toast } from 'react-toastify'
import * as paths from '../../routing/Paths'
import SearchBar from 'components/UI/SearchBar'
import NoResultsFound from 'components/UI/NoResultsFound/NoResultsFound'
import { isNil } from 'lodash'
import SearchBarStore from '../../components/UI/SearchBar/SearchBarStore'
import { AlbumSearchUrlQueryParams, Filters } from '../../components/UI/SearchBar/SearchBar'
import { useQuery } from '../../hooks/useQuery'
import { Event } from 'shared/models/Event'
import Button from 'components/UI/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import ConfigService, { ConfigKeys } from 'config'
import { useHistory } from 'react-router'
import SkeletonLoaderFeed from 'components/UI/Loaders/Skeleton/SkeletonLoaderFeed'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import axios from 'services/axios'
import EventsSwitcher from './EventDetails/components/EventsSwitcher'

const EventFilters = [
  Filters.Country,
  Filters.Event,
  Filters.Location,
  Filters.Activity,
  Filters.DateRange,
]

const Events = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { authStore, eventStore, activityStore, locationStore } = useContext(StoresContext)!
  const [isFutureEventsActive, setIsFutureEventsActive] = useState(false)
  const [showRequestEventButton, setShowRequestEventButton] = useState(true)
  const initialRender = useRef(true)

  const query = useQuery()
  const countryCode =
    query.get(AlbumSearchUrlQueryParams.CountryCode) ||
    locationStore.userLocation.country.code ||
    undefined
  const locationId = query.get(AlbumSearchUrlQueryParams.LocationId) || undefined
  const activityId = query.get(AlbumSearchUrlQueryParams.ActivityId) || undefined

  const isAuthenticated = authStore.isAuthenticated()

  SetPageTitle('Events')

  const [searchBarStore] = useState(
    () =>
      new SearchBarStore(eventStore, activityStore, locationStore, {
        countryCode,
        locationId,
        activityId,
      })
  )
  const [eventsStore] = useState(() => new EventsStore(authStore, searchBarStore))

  const fetchNextPage = () => {
    if (!isFutureEventsActive) {
      eventsStore.fetchNextPage()
    } else {
      eventsStore.fetchNextPage(true)
    }
  }

  const handleRequestEventCreation = () => {
    const url = ConfigService.getValue(ConfigKeys.EVENT_REQUEST_FORM)
    window.open(url, '_blank')
  }

  const getLoggedUser = () => {
    if (isAuthenticated) {
      return authStore.getLoggedUser()
    } else {
      return
    }
  }

  const userId = isAuthenticated ? getLoggedUser()?.id : null
  const isPhotographer = isAuthenticated ? getLoggedUser()?.isPhotographer : null
  const isPhotographerAuthenticated = !isNil(getLoggedUser()) && isPhotographer

  useEffect(() => {
    if (initialRender.current && eventsStore.events.length === 0) {
      Tracker.pageView('/events', 'Events')
      initialRender.current = false
    }

    const isSearchingFutureEvents = query.get('future') === 'true'
    if (isSearchingFutureEvents) {
      setIsFutureEventsActive(true)
      eventsStore.fetchEvents(true, true)
    } else {
      setIsFutureEventsActive(false)
      eventsStore.fetchEvents(true, false)
    }
  }, [])

  useEffect(() => {
    if (isPhotographerAuthenticated) {
      eventsStore.fetchPhotographerAccreditations()
    }
  }, [eventsStore.accreditations.length])

  const goToEventDetails = (event: Event) => {
    const eventDetailsUrl = paths.feedEventDetailsByLandingPath(event.landingPath)
    history.push(eventDetailsUrl)
  }

  const loadingSpinner = <Spinner divStyles="flex justify-center mt-5 items-center" size={40} />

  const handleAccreditationRequest = (eventId: string, requesterId: string) => {
    eventsStore.requestEventAccreditation(eventId, requesterId).then((response) => {
      if (response) {
        toast.success(t('Accreditation request successfully done!'), {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
    })
  }

  const today = new Date()
  const todaysDateFormatted = formatUtcToLocaleDate(today)

  useEffect(() => {
    //handle future dateRange values
    if (
      searchBarStore.dateRange &&
      searchBarStore.dateRange[0] &&
      new Date(searchBarStore.dateRange[0]) > today &&
      !isNil(searchBarStore.dateRange[1])
    ) {
      setIsFutureEventsActive(true)
      eventsStore.fetchEvents(true, true)
    }

    //handle past dateRange values
    if (
      searchBarStore.dateRange &&
      searchBarStore.dateRange[1] &&
      new Date(searchBarStore.dateRange[1]) < today &&
      !isNil(searchBarStore.dateRange[0])
    ) {
      setIsFutureEventsActive(false)
      eventsStore.fetchEvents(true, false)
    }
  }, [searchBarStore.dateRange, isFutureEventsActive])

  return (
    <div className="bg-bg_details">
      <div className="mx-4">
        <div className="my-4 bg-white px-2.5 pb-0.5 rounded-md shadow-md">
          <SearchBar
            searchBarStore={searchBarStore}
            onSearch={() => eventsStore.fetchEvents(true, isFutureEventsActive)}
            filters={EventFilters}
          />
        </div>
        <div className="flex justify-center w-full pb-3">
          <EventsSwitcher
            searchBarStore={searchBarStore}
            eventsStore={eventsStore}
            isFutureEventsActive={isFutureEventsActive}
            setIsFutureEventsActive={setIsFutureEventsActive}
          />
        </div>
        {isFutureEventsActive && showRequestEventButton && isPhotographerAuthenticated && (
          <div className="flex flex-col items-center my-4 p-3 bg-white border rounded-md">
            <div className="flex justify-between w-full">
              <span className="invisible" />
              <span className="text-lumepic-light_black font-medium mb-0.5">
                {t('Haven’t you found the event?')}
              </span>
              <FontAwesomeIcon
                icon={faXmark}
                className="text-lg cursor-pointer"
                onClick={() => setShowRequestEventButton(false)}
              />
            </div>
            <span className="font-light text-lumepicSm mb-3 text-center">
              {t(
                'If you know of another upcoming event you might be interested in attending, you can click the button below to request to be added!'
              )}
            </span>
            <Button
              btnType="PrimaryAction"
              onClick={handleRequestEventCreation}
              extraStyle="text-base"
            >
              {t('Request an event')}
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className="text-lumepic-black ml-1.5"
              />
            </Button>
          </div>
        )}
        <div className="flex justify-center mt-2 mb-4 row">
          <InfiniteScroll
            dataLength={eventsStore.events.length}
            next={fetchNextPage}
            hasMore={eventsStore.hasMorePages()}
            loader={<></>}
            scrollThreshold={0.8}
            className="overflow-hidden"
          >
            <div className="grid grid-cols-1 gap-3 md:grid-cols-4">
              {eventsStore.events.length > 0 &&
                eventsStore.events.map((event) => (
                  <Card sx={{ maxWidth: 345 }} key={event.id} className="animate-fadeIn">
                    <CardActionArea onClick={() => goToEventDetails(event)}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={event.coverPhotographSrc}
                        alt="event-cover-photograph"
                      />

                      {event.isFree && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="w-full flex justify-center bg-primary"
                        >
                          <div className="text-black w-full p-1 rounded-md font-semibold text-center">
                            {t('Free photographs')}
                          </div>{' '}
                        </Typography>
                      )}
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {event.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {event.description}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {event.dateTo
                            ? `${formatUtcToLocaleDate(event.date)} - ${formatUtcToLocaleDate(
                                event.dateTo
                              )}`
                            : formatUtcToLocaleDate(event.date)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {event.location?.spotName}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    {event.accreditationsEnabled &&
                      event.date < todaysDateFormatted &&
                      isPhotographerAuthenticated && (
                        <div className="p-2 border-t m-2 flex flex-col items-center justify-center">
                          {!event.accreditationApproved ? (
                            <>
                              {!isNil(
                                eventsStore.accreditations.find((acc) => acc.eventId === event.id)
                              ) ? (
                                <>
                                  <Typography
                                    variant="body1"
                                    color="text.inherit"
                                    fontWeight={500}
                                    paddingBottom={1}
                                  >
                                    {t('Accreditation Requested')}
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      className="text-lumepic-success_dark_green font-light ml-1"
                                    />
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    fontWeight={500}
                                    paddingBottom={1}
                                  >
                                    {t(
                                      'You will be notified in case your application is approved.'
                                    )}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography
                                    variant="body2"
                                    color="text.inherit"
                                    fontWeight={400}
                                    paddingBottom={1}
                                  >
                                    {t(
                                      'This event requires accreditation for photographers. Photographers without accreditation would not be able to access the event.'
                                    )}
                                  </Typography>
                                  <Button
                                    onClick={() => handleAccreditationRequest(event.id, userId!)}
                                    isLoading={eventsStore.isLoadingAccreditations}
                                    loadingText={t('Loading')!}
                                    btnType={'SecondaryAction'}
                                    extraStyle="mb-2"
                                  >
                                    {t('Request Accreditation')}
                                  </Button>
                                </>
                              )}
                            </>
                          ) : (
                            <Typography className="text-lumepic-success_dark_green font-light">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-lumepic-success_dark_green font-light mr-1"
                              />
                              {t('Accredited')}
                            </Typography>
                          )}
                        </div>
                      )}
                  </Card>
                ))}
            </div>
          </InfiniteScroll>
        </div>
        {eventsStore.events.length == 0 && !eventsStore.isLoading && <NoResultsFound />}
        {eventsStore.isLoading && eventsStore.events.length === 0 && (
          <SkeletonLoaderFeed screenBreak={4} />
        )}
        {eventsStore.isLoading && eventsStore.events.length > 0 && loadingSpinner}
      </div>
    </div>
  )
}

export default withErrorHandler(observer(Events), axios)
