import i18next from 'i18next'
import * as yup from 'yup'

const getEventAlbumValidationSchema = (eventStartDate: Date, eventEndDate?: Date | null) => {
  return yup.object({
    description: yup.string().required(i18next.t('Description is required') as string),
    currency: yup.string().required(i18next.t('Currency is required') as string),
    defaultImagePrice: yup.number().min(1, i18next.t('Default image price is required') as string),
    takenDate: yup
      .date()
      .required(i18next.t('Date is required') as string)
      .test(
        'within-event-range',
        i18next.t('Date must be within event date range') as string,
        function (value) {
          if (!value) return false
          const start = new Date(eventStartDate)
          const end = eventEndDate ? new Date(eventEndDate) : start
          return value >= start && value <= end
        }
      ),
    activityId: yup
      .string()
      .uuid()
      .required(i18next.t('Activity is required') as string),
    locationId: yup
      .string()
      .uuid()
      .required(i18next.t('Location is required') as string),
    eventId: yup
      .string()
      .uuid()
      .required(i18next.t('Event is required') as string),
  })
}

export default getEventAlbumValidationSchema
