import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import CreateOrUpdateAlbumStore from '../../CreateOrUpdateAlbumStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { isNil } from 'lodash'
import Album from 'shared/models/Album'
import CreateAlbumInterface from '../../../../../services/RequestInterfaces/Album/CreateAlbum.interface'
import { useFormik } from 'formik'
import { Toast } from '../../../../../shared/util/toast'
import StoresContext from '../../../../../providers/storesContext'
import getEventAlbumValidationSchema from './EventAlbumValidationSchema'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import InputInformation from '../../../../../components/UI/InputInformation'
import { Currency } from '../../../../../shared/util/Currency'
import EventInformation from './EventInformation'
import Button from '../../../../../components/UI/Button'
import Breadcrumb from '../../../../../components/UI/Breadcrumb'
import Spinner from '../../../../../components/UI/Spinner'
import EditAlbumInterface from 'services/RequestInterfaces/Album/EditAlbum.interface'
import CustomCurrencyOptionComponent from '../../components/AutocompleteCustomCurrencyOption'
import { Event } from 'shared/models/Event'
import getFreeEventAlbumValidationSchema from './FreeEventAlbumValidationSchema'
import moment from 'moment'

type EventAlbumFormProps = {
  assignedEvent: Event
  albumToEdit?: Album
  handleFinish: (album: Album) => void
  store: CreateOrUpdateAlbumStore
}

const EventAlbumForm = ({
  albumToEdit,
  assignedEvent,
  handleFinish,
  store,
}: EventAlbumFormProps) => {
  const { t } = useTranslation()

  const { activityStore } = useContext(StoresContext)!
  const eventDetails = store.albumEvent

  useEffect(() => {
    if (isNil(eventDetails) && !store.isLoading) {
      store.setAlbumEvent(assignedEvent.id)
    }
  }, [])

  const isNewAlbum = isNil(albumToEdit)

  useEffect(() => {
    if (isNil(eventDetails?.activity)) {
      activityStore.fetchActivities()
    }
  }, [])

  const initialValues: CreateAlbumInterface | EditAlbumInterface = !isNewAlbum
    ? {
        id: albumToEdit?.id || '',
        description: albumToEdit?.description || '',
        currency: albumToEdit?.currency || 'uyu',
        defaultImagePrice: albumToEdit?.defaultImagePrice || 0,
        takenDate: albumToEdit.takenDate || new Date(Date.now()),
        activityId: albumToEdit.activity.id || '',
        locationId: albumToEdit.location.id || '',
        eventId: albumToEdit.event.id || assignedEvent.id,
      }
    : {
        description: '',
        currency: eventDetails?.currency || 'uyu',
        defaultImagePrice: eventDetails?.defaultImagePrice || 0,
        takenDate: new Date(eventDetails?.date || Date.now()),
        activityId: eventDetails?.activityId || '',
        locationId: eventDetails?.locationId || '',
        eventId: assignedEvent.id,
        isFree: assignedEvent.isFree || false,
      }

  const formik = useFormik({
    initialValues,
    validationSchema: assignedEvent.isFree
      ? getFreeEventAlbumValidationSchema(assignedEvent.date, assignedEvent?.dateTo)
      : getEventAlbumValidationSchema(assignedEvent.date, assignedEvent?.dateTo),
    onSubmit: (values) => {
      if (isNewAlbum) {
        store.createAlbum(values).then((newAlbum) => {
          if (newAlbum) {
            handleFinish(newAlbum)
          } else {
            Toast('Error creating album', 'error')
          }
        })
      } else {
        const editValues = values as EditAlbumInterface
        store.updateAlbum(editValues).then((updatedAlbum) => {
          if (updatedAlbum) {
            handleFinish(updatedAlbum)
          } else {
            Toast('Error updating album', 'error')
          }
        })
      }
    },
  })

  const activityOptions = activityStore.activities
    .map((activity) => {
      return { value: activity.id, label: activity.name.toUpperCase() }
    })
    .sort((a, b) => -b.label[0].localeCompare(a.label[0]))

  const currencies: string[] = Object.values(Currency)

  return (
    <>
      {store.isLoadingEventDetails || isNil(eventDetails) ? (
        <Spinner />
      ) : (
        <>
          {isNewAlbum ? (
            <>
              <div className="flex justify-center w-full my-2 sm:hidden">
                <Breadcrumb
                  completedSteps={1}
                  totalSteps={isNil(eventDetails.quantityDiscountId) ? 5 : 4}
                />
              </div>
              <div className="mb-2 text-xl font-extrabold text-gray-800">
                {t('Add album to') + ' ' + eventDetails?.name}
              </div>
            </>
          ) : (
            <div className="mb-2 text-xl font-extrabold text-gray-800">
              {t('Edit album from') + ' ' + albumToEdit.event.name}
            </div>
          )}
          <div className="flex flex-col overflow-y-auto">
            <div className="flex flex-col gap-0.5 mt-2">
              <TextField
                fullWidth
                id="description"
                name="description"
                label={t('Description')}
                required
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
              />
              <InputInformation
                informationText={
                  'Text visible for athletes: add here album details (specific hour, location or other)'
                }
              />
            </div>
            {!eventDetails.isFree && (
              <div className="flex flex-col w-full gap-2 mt-2 md:flex-row">
                {isNil(eventDetails?.currency) && (
                  <div className={`flex flex-col gap-0.5 mt-2 w-full`}>
                    <Autocomplete
                      options={currencies}
                      value={
                        currencies.find((currency) => currency === formik.values.currency) || null
                      }
                      getOptionLabel={(option: string) => option}
                      onChange={(code, newValue) => {
                        formik.setFieldValue('currency', newValue ? newValue : '')
                      }}
                      renderOption={(props, option) => (
                        <CustomCurrencyOptionComponent option={option} {...props} />
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          name="currency"
                          id="currency"
                          label={t('Currency')}
                          required
                          error={formik.touched.currency && Boolean(formik.errors.currency)}
                          helperText={formik.touched.currency && formik.errors.currency}
                        />
                      )}
                    />
                    <InputInformation
                      informationText={'Album will be sold in the Currency you select.'}
                    />
                  </div>
                )}
                {isNil(eventDetails?.defaultImagePrice) && (
                  <div className={`flex flex-col gap-0.5 mt-2 w-full`}>
                    <TextField
                      fullWidth
                      id="defaultImagePrice"
                      name="defaultImagePrice"
                      label={t('Default Image Price')}
                      required
                      type="number"
                      value={formik.values.defaultImagePrice}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.defaultImagePrice && Boolean(formik.errors.defaultImagePrice)
                      }
                      helperText={
                        formik.touched.defaultImagePrice && formik.errors.defaultImagePrice
                      }
                    />
                    <InputInformation
                      informationText={
                        'In case you want to set different prices on each photo, you can edit later from My Albums'
                      }
                    />
                  </div>
                )}
              </div>
            )}
            <div className="flex flex-col w-full">
              {eventDetails.dateTo && (
                <div className="flex flex-col w-1/2 mt-3">
                  <TextField
                    fullWidth
                    id="takenDate"
                    name="takenDate"
                    label={t('Date')}
                    required
                    InputLabelProps={{ shrink: true }}
                    type="Date"
                    inputProps={{
                      min: moment.utc(eventDetails.date).format('YYYY-MM-DD'),
                      max: moment.utc(eventDetails.dateTo).format('YYYY-MM-DD'),
                    }}
                    value={moment.utc(formik.values.takenDate).format('YYYY-MM-DD')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.takenDate && Boolean(formik.errors.takenDate)}
                    helperText={
                      formik.touched.takenDate
                        ? typeof formik.errors.takenDate === 'string'
                          ? formik.errors.takenDate
                          : ''
                        : ''
                    }
                  />
                  <InputInformation
                    informationText={t(
                      'Select the specific date when these photographs were taken'
                    )}
                  />
                </div>
              )}
              {isNil(eventDetails?.activity) && (
                <div className="flex flex-col w-1/2 mt-3">
                  <Autocomplete
                    options={activityOptions}
                    value={
                      activityOptions.find(
                        (activity) => activity.value === formik.values.activityId
                      ) || null
                    }
                    getOptionLabel={(option) => option.label}
                    onChange={(activity, newValue) => {
                      formik.setFieldValue('activityId', newValue ? newValue.value : '')
                    }}
                    loading={activityStore.isLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        name="activityId"
                        id="activityId"
                        label={t('Sport')}
                        required
                        error={formik.touched.activityId && Boolean(formik.errors.activityId)}
                        helperText={formik.touched.activityId && formik.errors.activityId}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {activityStore.isLoading ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <EventInformation eventDetails={eventDetails} isNewAlbum={isNewAlbum} />
            <div className={`flex ${isNewAlbum ? 'justify-between' : 'justify-end'} mt-4`}>
              {isNewAlbum && (
                <div className="hidden sm:block">
                  <Breadcrumb
                    completedSteps={1}
                    totalSteps={isNil(eventDetails.quantityDiscountId) ? 5 : 4}
                  />
                </div>
              )}
              <Button
                btnType="PrimaryAction"
                isLoading={store.isLoading}
                onClick={formik.handleSubmit}
                extraStyle="w-full sm:w-min"
                type={'button'}
              >
                <div className="flex items-center justify-center gap-2">
                  {isNewAlbum ? (
                    <>
                      <span>{t('Next')}</span>
                      <FontAwesomeIcon icon={faArrowRight} className="text-lg" />
                    </>
                  ) : (
                    <span>{t('Save')}</span>
                  )}
                </div>
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default observer(EventAlbumForm)
