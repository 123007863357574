import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faXmark } from '@fortawesome/free-solid-svg-icons'
import { isNil } from 'lodash'
import { observer } from 'mobx-react'
import EventDetailsStore from 'containers/Events/EventDetails/EventDetailsStore'
import AlbumDetailsStore from 'components/AlbumDetails/AlbumDetailsStore'
import ItemDetailsSearchBar from './ItemDetailsSearchBar'
import { DropdownOption } from 'components/UI/Dropdown/Dropdown'

type FiltersAccordionProps = {
  isAccordionExpanded: boolean
  setIsAccordionExpanded: (expanded: boolean) => void
  eventDetailsStore?: EventDetailsStore
  albumDetailsStore?: AlbumDetailsStore
  handleClearFilters: () => void
  handleOpenUploadSelfieModal: () => void
  handleSearchByActivity?: () => void
  handleOpenFilterByTagModal: () => void
  handleOpenFilterByDateAndTimeModal: () => void
  searchInProgress: boolean
  moreThanFiveDays: boolean
  days?: DropdownOption[]
}

const ItemDetailsFiltersAccordion = ({
  isAccordionExpanded,
  setIsAccordionExpanded,
  eventDetailsStore,
  albumDetailsStore,
  handleClearFilters,
  handleOpenUploadSelfieModal,
  handleSearchByActivity,
  handleOpenFilterByTagModal,
  handleOpenFilterByDateAndTimeModal,
  searchInProgress,
  moreThanFiveDays,
  days,
}: FiltersAccordionProps) => {
  const { t } = useTranslation()

  const hasActiveFilters = useMemo(() => {
    if (eventDetailsStore) {
      return (
        (eventDetailsStore.tagValue.value?.length > 0 && searchInProgress) ||
        eventDetailsStore.isFilteringByFaceRecognition ||
        !isNil(eventDetailsStore.activity) ||
        !isNil(eventDetailsStore.dateRange[0]) ||
        !isNil(eventDetailsStore.dateRange[1])
      )
    } else if (albumDetailsStore) {
      return (
        (albumDetailsStore.tagValue.value?.length > 0 && searchInProgress) ||
        albumDetailsStore.isFilteringByFaceRecognition ||
        !isNil(albumDetailsStore.dateRange[0]) ||
        !isNil(albumDetailsStore.dateRange[1])
      )
    }
    return false
  }, [eventDetailsStore, albumDetailsStore, searchInProgress])

  return (
    <Accordion
      defaultExpanded={true}
      className="w-full"
      expanded={isAccordionExpanded}
      onChange={(event, expanded) => setIsAccordionExpanded(expanded)}
      sx={{
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        marginTop: '1rem !important',
        '&.MuiAccordion-root': {
          borderRadius: '8px',
          overflow: 'hidden',
          '&:before': {
            display: 'none',
          },
        },
        '& .MuiAccordionSummary-root': {
          backgroundColor: 'white',
          borderRadius: '8px',
          minHeight: '48px !important',
          '&.Mui-expanded': {
            minHeight: '48px !important',
          },
        },
        '& .MuiAccordionSummary-content': {
          margin: '12px 0 !important',
          '&.Mui-expanded': {
            margin: '12px 0 !important',
          },
        },
        '& .MuiAccordionDetails-root': {
          borderTop: isAccordionExpanded ? '1px solid #e0e0e0' : 'none',
          backgroundColor: 'white',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="filters-content"
        id="filters-header"
      >
        <div className="w-full flex justify-between items-center">
          <div className="flex items-center gap-1.5">
            <FontAwesomeIcon icon={faFilter} className="text-lumepic-grey lg:text-lg" />
            <header className="lg:text-lg">{t('Filters')}</header>
          </div>
          <div>
            {hasActiveFilters ? (
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  handleClearFilters()
                }}
                className="w-full sm:w-auto flex gap-2 items-center justify-center px-2 py-1 rounded-md bg-lumepic-light_black hover:lg:scale-105 transition duration-200"
              >
                <FontAwesomeIcon icon={faXmark} className="text-white" />
                <span className="text-sm text-white">{t('Delete filters')}</span>
              </button>
            ) : (
              <span className="text-primary_dark text-sm px-2 py-1">{t('Recommended')}</span>
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className="bg-white w-full p-0">
        <div className="flex flex-col gap-3 mb-2 lg:px-2.5 pb-2.5 pt-1.5">
          <ItemDetailsSearchBar
            eventDetailsStore={eventDetailsStore}
            albumDetailsStore={albumDetailsStore}
            handleOpenUploadSelfieModal={handleOpenUploadSelfieModal}
            handleSearchByActivity={handleSearchByActivity ?? undefined}
            handleOpenFilterByTagModal={handleOpenFilterByTagModal}
            handleOpenFilterByDateAndTimeModal={handleOpenFilterByDateAndTimeModal}
            searchInProgress={searchInProgress}
            moreThanFiveDays={moreThanFiveDays}
            days={days}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default observer(ItemDetailsFiltersAccordion)
