import Album from '../Album'
import { Photograph } from '../Photograph'
import { CodeDiscount } from '../CodeDiscount'
import { isNil } from 'lodash'
import { Currency } from '../../util/Currency'
import { CartLine, CartLineType } from './CartLine/CartLine'
import { PhotographCartLine } from './CartLine/PhotographCartLine'
import { PackageCartLine } from './CartLine/PackageCartLine'
import { CodeDiscountUnit } from '../../../services/RequestInterfaces/Discount/CodeDiscount.interface'
import { QuantityDiscount } from '../QuantityDiscount'

export enum TagPackageType {
  Face = 'FACE',
  Text = 'TEXT',
}

export enum BurstPackageType {
  Burst = 'BURST',
}

/**
 * Class representing a shopping cart.
 */
export default class Cart {
  /** The lines in the cart. */
  lines: CartLine[]
  /** The currency of the cart. */
  currency: Currency | undefined
  /** The code discounts applied to the cart. */
  codeDiscounts: CodeDiscount[]

  /**
   * Creates an instance of Cart.
   */
  constructor() {
    this.lines = []
    this.codeDiscounts = []
  }

  /**
   * Returns the total price before discounts.
   * @returns The total price before discounts.
   */
  get subTotal(): number {
    return this.lines.map((line) => line.subtotalPrice).reduce((price, accum) => price + accum, 0)
  }

  get discountTotal(): number {
    return this.lines.map((line) => line.discountTotal).reduce((price, accum) => price + accum, 0)
  }

  get totalPrice(): number {
    // First calculate total with quantity discounts applied
    const linesTotalWithQuantityDiscounts = this.lines
      .map((line) => line.totalPrice)
      .reduce((price, accum) => price + accum, 0)

    // Then apply code discounts to the already discounted total
    const codeDiscountsTotal = this.codeDiscounts.reduce((total, discount) => {
      switch (discount.unit) {
        case CodeDiscountUnit.PERCENTAGE:
          return total + linesTotalWithQuantityDiscounts * (discount.amount / 100)
        case CodeDiscountUnit.CURRENCY:
          return total + discount.amount
        default:
          return total
      }
    }, 0)

    return Math.max(0, linesTotalWithQuantityDiscounts - codeDiscountsTotal)
  }

  /**
   * Returns a string representation of the cart.
   * @returns A JSON string representation of the cart.
   */
  toString() {
    return JSON.stringify({
      ...this,
      lastModifiedAt: new Date(),
    })
  }

  /**
   * Initializes a new Cart instance from a given cart object.
   * @param cart - The cart object to initialize from.
   * @returns A new Cart instance.
   * TODO: Add ICart Interface
   */
  static init(cart: any): Cart {
    const newCart = new Cart()
    try {
      newCart.currency = cart.currency
      newCart.codeDiscounts = cart.codeDiscounts
      for (const line of cart.lines) {
        if (line.type === CartLineType.Photograph) {
          const photograph = Photograph.init(line.photograph)
          const cartLine = new PhotographCartLine(
            photograph,
            line.subtotalPrice,
            line.totalPrice,
            line.discountTotal,
            line.currency,
            line.album,
            line.event
          )
          newCart.lines.push(cartLine)
        } else if (line.type === CartLineType.Package) {
          const photographs = line.previewPhotographs.map((photo: any) => Photograph.init(photo))
          const cartLine = new PackageCartLine(
            line.subtotalPrice,
            line.totalPrice,
            line.discountTotal,
            line.currency,
            line.packageType,
            line.tagId,
            photographs,
            line.quantityOfPhotographs,
            line.albums,
            line.event
          )
          newCart.lines.push(cartLine)
        }
      }
    } catch (e) {
      console.error(e)
    }
    return newCart
  }

  /**
   * Clones the cart.
   * @param cart - The cart to clone.
   * @returns A new Cart instance.
   */
  static clone(cart: Cart): Cart {
    const newCart = new Cart()
    newCart.currency = cart.currency
    newCart.codeDiscounts = cart.codeDiscounts
    for (const line of Array.from(cart.lines.values())) {
      if (line.type === CartLineType.Photograph) {
        const castedLine = line as PhotographCartLine
        const cartLine = new PhotographCartLine(
          castedLine.photograph,
          castedLine.subtotalPrice,
          castedLine.totalPrice,
          castedLine.discountTotal,
          castedLine.currency,
          castedLine.album,
          castedLine.album.event
        )
        newCart.lines.push(cartLine)
      } else if (line.type === CartLineType.Package) {
        const castedLine = line as PackageCartLine
        const cartLine = new PackageCartLine(
          castedLine.subtotalPrice,
          castedLine.totalPrice,
          castedLine.discountTotal,
          castedLine.currency,
          castedLine.packageType,
          castedLine.tagId,
          castedLine.previewPhotographs,
          castedLine.quantityOfPhotographs,
          castedLine.albums,
          castedLine.event
        )
        newCart.lines.push(cartLine)
      }
    }
    return newCart
  }

  /**
   * Checks if a photograph is already in the cart.
   * @param photograph - The photograph to check.
   * @returns True if the photograph is in the cart, false otherwise.
   */
  alreadyInCart(photograph: Photograph): boolean {
    return this.lines.some((cartLine: CartLine) => {
      if (cartLine.type === CartLineType.Photograph) {
        const castedLine = cartLine as PhotographCartLine
        return castedLine.photograph.id === photograph.id
      } else {
        return !isNil(photograph.tags.find((tag) => tag.id === (cartLine as PackageCartLine).tagId))
      }
    })
  }

  /**
   * Checks if a photograph is already in a package.
   * @param photograph - The photograph to check.
   * @returns True if the photograph is in the package, false otherwise.
   */
  alreadyInPackage(photograph: Photograph): boolean {
    return this.lines.some((cartLine: CartLine) => {
      if (cartLine.type === CartLineType.Package) {
        const castedLine = cartLine as PackageCartLine
        return !isNil(photograph.tags.find((tag) => tag.id === castedLine.tagId))
      }
      return false
    })
  }

  /**
   * Resets the cart.
   */
  reset() {
    this.lines = []
  }

  public getSinglePhotographCount(albumId?: string, eventId?: string): number {
    const singlePhotographs = this.lines.filter((line) => {
      if (line.type === CartLineType.Photograph) {
        const castedLine = line as PhotographCartLine
        return (
          (albumId && castedLine.photograph.albumId === albumId) ||
          (eventId && castedLine.event?.id === eventId)
        )
      }
      return false
    }).length
    return singlePhotographs
  }

  public getPackagePhotographs(albumId?: string, eventId?: string): number {
    const packagePhotographs = this.lines
      .filter((line) => {
        if (line.type === CartLineType.Package) {
          const castedLine = line as PackageCartLine
          return (
            (albumId && castedLine.albums[0]?.id === albumId) ||
            (eventId && castedLine.event?.id === eventId)
          )
        }
        return false
      })
      .reduce((total, line) => total + (line as PackageCartLine).quantityOfPhotographs, 0)

    return packagePhotographs
  }

  /**
   * Returns the number of photographs in the cart for a given album and event.
   * @param albumId - Optional album ID.
   * @param eventId - Optional event ID.
   * @returns The number of photographs in the cart.
   */
  public getPhotographCount(albumId?: string, eventId?: string): number {
    return (
      this.getSinglePhotographCount(albumId, eventId) + this.getPackagePhotographs(albumId, eventId)
    )
  }

  /**
   * Returns the photographs in the cart.
   * @returns An array of Photograph objects.
   */
  get photographs(): Photograph[] {
    return this.lines
      .map((line) => {
        if (line.type === CartLineType.Photograph) {
          return (line as PhotographCartLine).photograph
        } else {
          return (line as PackageCartLine).previewPhotographs
        }
      })
      .flat()
  }

  /**
   * Returns the packages discount amount.
   * @returns The packages discount amount.
   */
  getPackagesDiscountAmount(groupLine?: CartLine[]): number {
    if (!groupLine) {
      return this.calculateTotalPackageDiscount()
    }

    return groupLine
      .filter((line) => line.type === CartLineType.Package)
      .reduce((total, line) => total + line.discountTotal, 0)
  }

  /**
   * Returns the bursts discount amount.
   * @returns The bursts discount amount.
   */
  getBurstsDiscountAmount(groupLine?: CartLine[]): number {
    if (!groupLine) {
      return this.calculateTotalBurstDiscount()
    }

    return groupLine
      .filter((line) => line.type === CartLineType.Burst)
      .reduce((total, line) => total + line.discountTotal, 0)
  }

  /**
   * Returns the code discounts amount.
   * @returns The code discounts amount.
   */
  getCodeDiscountsAmount(groupLine?: CartLine[]): number {
    if (!groupLine) {
      return this.calculateTotalCodeDiscount()
    }

    let totalDiscountAmount = 0
    const groupSubtotal = groupLine.reduce((total, line) => total + line.subtotalPrice, 0)

    const quantityDiscountAmount = this.getQuantityDiscountAmount(groupLine)

    const subtotalAfterQuantityDiscounts = groupSubtotal - quantityDiscountAmount

    this.codeDiscounts.forEach((discount) => {
      switch (discount.unit) {
        case CodeDiscountUnit.PERCENTAGE:
          totalDiscountAmount += subtotalAfterQuantityDiscounts * (discount.amount / 100)
          break
        case CodeDiscountUnit.CURRENCY: {
          const cartSubtotal = this.subTotal
          const groupProportion = subtotalAfterQuantityDiscounts / cartSubtotal
          totalDiscountAmount += discount.amount * groupProportion
          break
        }
        default:
          break
      }
    })

    return totalDiscountAmount
  }

  /**
   * Returns the quantity discount percentage earned.
   * @param numberOfPhotographs - The number of photographs.
   * @param album - Optional album object.
   * @returns The quantity discount percentage earned.
   */
  public getQuantityDiscountPercentageEarned(numberOfPhotographs: number, album?: Album): number {
    if (!album) return 0

    let quantityDiscount: QuantityDiscount | null = null
    if (album.event && album.event.quantityDiscount) {
      quantityDiscount = album.event.quantityDiscount
    } else if (album.quantityDiscount) {
      quantityDiscount = album.quantityDiscount
    }

    if (!quantityDiscount) return 0

    const applicableDiscounts = quantityDiscount.discountPercentages.filter(
      (discountPercentage) => discountPercentage.numberOfPictures <= numberOfPhotographs
    )

    if (applicableDiscounts.length > 0) {
      return Math.max(
        ...applicableDiscounts.map((discountPercentage) => discountPercentage.discountPercentage)
      )
    }

    return 0
  }

  /**
   * Returns the quantity discount amount.
   * @returns The quantity discount amount.
   */
  getQuantityDiscountAmount(groupLine?: CartLine[]): number {
    if (!groupLine) {
      return this.calculateTotalQuantityDiscount()
    }

    const nonPackageLines = groupLine.filter(
      (line) => line.type === CartLineType.Photograph
    ) as PhotographCartLine[]

    if (nonPackageLines.length === 0) {
      return 0
    }

    const albums = nonPackageLines
      .map((line) => line.album)
      .filter((album, index, self) => {
        return self.findIndex((a) => a?.id === album?.id) === index
      })

    const numberOfPhotographs = nonPackageLines.length
    const discountPercentage = this.getQuantityDiscountPercentageEarned(
      numberOfPhotographs,
      albums[0]
    )

    const groupSubtotal = nonPackageLines
      .map((line) => line.photograph.price)
      .reduce((a, b) => a + b, 0)

    return (groupSubtotal * discountPercentage) / 100
  }

  /**
   * Groups cart lines by event ID.
   * @param lines - Array of PhotographCartLine objects to be grouped
   * @returns A Map where keys are event IDs and values are arrays of cart lines belonging to that event
   */
  private groupLinesByEvent(lines: PhotographCartLine[]): Map<string, PhotographCartLine[]> {
    const eventGroups = new Map<string, PhotographCartLine[]>()

    lines.forEach((line) => {
      if (line.event) {
        const eventId = line.event.id
        if (!eventGroups.has(eventId)) {
          eventGroups.set(eventId, [])
        }
        eventGroups.get(eventId)!.push(line)
      }
    })

    return eventGroups
  }

  /**
   * Groups cart lines by album ID.
   * @param lines - Array of PhotographCartLine objects to be grouped
   * @returns A Map where keys are album IDs and values are arrays of cart lines belonging to that album
   */
  private groupLinesByAlbum(lines: PhotographCartLine[]): Map<string, PhotographCartLine[]> {
    const albumGroups = new Map<string, PhotographCartLine[]>()

    lines.forEach((line) => {
      const albumId = line.album.id
      if (!albumGroups.has(albumId)) {
        albumGroups.set(albumId, [])
      }
      albumGroups.get(albumId)!.push(line)
    })

    return albumGroups
  }

  /**
   * Calculates total quantity discounts for photographs grouped by event.
   * For each event, groups photographs by album and calculates applicable discounts
   * based on the total number of photographs in each album.
   * @param eventGroups - Map of event IDs to arrays of cart lines
   * @returns Total discount amount for all event-related photographs
   */
  private calculateEventGroupDiscounts(eventGroups: Map<string, PhotographCartLine[]>): number {
    let totalDiscount = 0

    eventGroups.forEach((eventLines) => {
      const albumGroups = this.groupLinesByAlbum(eventLines)

      albumGroups.forEach((albumLines, albumId) => {
        const album = albumLines[0].album
        const event = albumLines[0].event

        const totalPhotos = this.getPhotographCount(albumId, event?.id)

        const discountPercentage = this.getQuantityDiscountPercentageEarned(totalPhotos, album)

        const albumTotal = albumLines.reduce((sum, line) => sum + line.photograph.price, 0)

        totalDiscount += (albumTotal * discountPercentage) / 100
      })
    })

    return totalDiscount
  }

  /**
   * Calculates total quantity discounts for photographs grouped by album (non-event related).
   * For each album, calculates applicable discounts based on the total number of photographs.
   * @param albumGroups - Map of album IDs to arrays of cart lines
   * @returns Total discount amount for all non-event photographs
   */
  private calculateAlbumGroupDiscounts(albumGroups: Map<string, PhotographCartLine[]>): number {
    let totalDiscount = 0

    albumGroups.forEach((albumLines, albumId) => {
      const album = albumLines[0].album

      const totalPhotos = this.getPhotographCount(albumId)

      const discountPercentage = this.getQuantityDiscountPercentageEarned(totalPhotos, album)

      const albumTotal = albumLines.reduce((sum, line) => sum + line.photograph.price, 0)

      totalDiscount += (albumTotal * discountPercentage) / 100
    })

    return totalDiscount
  }

  private calculateTotalQuantityDiscount(): number {
    const nonPackageLines = this.lines.filter(
      (line) => line.type === CartLineType.Photograph
    ) as PhotographCartLine[]

    const eventGroups = this.groupLinesByEvent(nonPackageLines)
    const eventDiscounts = this.calculateEventGroupDiscounts(eventGroups)

    const nonEventGroups = this.groupLinesByAlbum(nonPackageLines.filter((line) => !line.event))
    const nonEventDiscounts = this.calculateAlbumGroupDiscounts(nonEventGroups)

    return eventDiscounts + nonEventDiscounts
  }

  private calculateTotalCodeDiscount(): number {
    let totalDiscountAmount = 0
    this.codeDiscounts.forEach((discount) => {
      switch (discount.unit) {
        case CodeDiscountUnit.PERCENTAGE:
          totalDiscountAmount += this.subTotal * (discount.amount / 100)
          break
        case CodeDiscountUnit.CURRENCY:
          totalDiscountAmount += discount.amount
          break
        default:
          break
      }
    })
    return totalDiscountAmount
  }

  private calculateTotalPackageDiscount(): number {
    return this.lines
      .filter((line) => line.type === CartLineType.Package)
      .reduce((total, line) => total + line.discountTotal, 0)
  }

  private calculateTotalBurstDiscount(): number {
    return this.lines
      .filter((line) => line.type === CartLineType.Burst)
      .reduce((total, line) => total + line.discountTotal, 0)
  }
}
