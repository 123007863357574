import {
  faArrowRight,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Breadcrumb from 'components/UI/Breadcrumb'
import Button from 'components/UI/Button'
import CoverPhotographUploaded from 'components/UI/ImageUploaded/CoverPhotographUploaded'
import { isNil } from 'lodash'
import { observer } from 'mobx-react'
import storesContext from 'providers/storesContext'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Album from 'shared/models/Album'
import { ProgressInfo } from '../UploadImageToAlbum/UploadImageToAlbumStore'
import UploadCoverPhotographStore from './UploadCoverPhotographStore'
import { Event } from 'shared/models/Event'
import ImageUploader from 'components/UI/ImageUploader/ImageUploader'

type UploadCoverPhotographProps = {
  album: Album
  handleFinish: (album: Album) => void
  isCreating: boolean
  albumEvent: Event | undefined
  setCoverUploaded: () => void
}

const UploadCoverPhotograph = ({
  album,
  handleFinish,
  isCreating,
  albumEvent,
  setCoverUploaded,
}: UploadCoverPhotographProps) => {
  const { authStore } = useContext(storesContext)!
  const { t } = useTranslation()
  const [store] = useState(() => {
    return new UploadCoverPhotographStore(authStore)
  })

  useEffect(() => {
    if (!isCreating) {
      store.changeCoverPhotographUrl(album.coverPhotographSrc)
    }
  }, [])

  const auxSetProgressInfo = useCallback((pginfo: ProgressInfo) => {
    store.changeProgressInfo(pginfo)
  }, [])

  const onSubmitImage = (image: File) => {
    store.changeImageSubmitted(true)
    store.changeImage(image)
    const progressInfo = {
      percentage: 0,
      fileName: image.name,
      requestSent: false,
      error: false,
      size: image.size,
      type: image.type,
    }
    auxSetProgressInfo(progressInfo)
  }

  useEffect(() => {
    if (store.imageSubmitted && !isNil(store.image)) {
      store.uploadCoverPhotograph(album.id)
      setCoverUploaded()
    } else if (store.imageSubmitted && isNil(store.image)) {
      store.changeImageSubmitted(false)
    }
  }, [auxSetProgressInfo, store.image])

  let imageDisplayView
  !store.coverPhotographUrl
    ? (imageDisplayView = <ImageUploader onUpload={onSubmitImage} />)
    : (imageDisplayView = <CoverPhotographUploaded onUpload={onSubmitImage} store={store} />)

  return (
    <>
      <div className="flex flex-col">
        {isCreating && (
          <div className="sm:hidden mt-3 flex justify-center ml-2">
            <Breadcrumb
              completedSteps={!isNil(albumEvent) && !isNil(albumEvent.quantityDiscount) ? 2 : 3}
              totalSteps={!isNil(albumEvent) && !isNil(albumEvent.quantityDiscount) ? 4 : 5}
            />
          </div>
        )}
        <h3 className="font-semibold mt-2 mb-0.5 text-xl text-start">
          {isCreating ? t('Upload a cover photograph') : t('Update cover photograph')}
        </h3>
        <p className="text-sm text-lumepic-light_black mb-3">
          {t('Choose the best picture from your album and boost your sales!')}
        </p>
        <p className="text-xs text-lumepic-light_black mb-1 flex items-center gap-1 p-1 bg-bg_details rounded-md w-max">
          <FontAwesomeIcon icon={faInfoCircle} />
          {t('Make sure it is 16:9 dimensions for the best framing.')}
        </p>
        <p className="text-xs text-lumepic-light_black mb-1 flex items-center gap-1 p-1 bg-bg_details rounded-md w-max">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          {t('Do not include logos, watermarks, contact information or price on the cover')}.
        </p>
        <div className="w-full h-full max-h-full overflow-hidden">{imageDisplayView}</div>
        <div className={`flex ${isCreating ? 'justify-between' : 'justify-end'} mb-3 mt-4`}>
          {isCreating && (
            <div className="hidden sm:block">
              <Breadcrumb
                completedSteps={!isNil(albumEvent) && !isNil(albumEvent.quantityDiscount) ? 2 : 3}
                totalSteps={!isNil(albumEvent) && !isNil(albumEvent.quantityDiscount) ? 4 : 5}
              />
            </div>
          )}
          {store.imageUploaded() ? (
            <Button
              btnType="PrimaryAction"
              isLoading={store.isLoading}
              onClick={() => handleFinish(album)}
              extraStyle="w-full sm:w-min"
            >
              <div className="flex gap-2 items-center justify-center">
                {isCreating ? (
                  <>
                    <span>{t('Next')}</span>
                    <FontAwesomeIcon icon={faArrowRight} className="text-lg" />
                  </>
                ) : (
                  <span>{t('Save')}</span>
                )}
              </div>
            </Button>
          ) : (
            <Button
              btnType="PrimaryAction"
              onClick={() => {}}
              extraStyle="w-full sm:w-min opacity-60"
            >
              <div className="flex gap-2 items-center justify-center cursor-not-allowed">
                {isCreating ? (
                  <>
                    <span>{t('Next')}</span>
                    <FontAwesomeIcon icon={faArrowRight} className="text-lg" />
                  </>
                ) : (
                  <span>{t('Save')}</span>
                )}
              </div>
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default observer(UploadCoverPhotograph)
